import React from 'react';
import { Container, Gap } from './styles';

export default function ContentLeft() {
  return (
    <Container>
      <Gap />
    </Container>
  );
}
