import styled from 'styled-components';

export const PopupContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  overflow-y: auto;
`;

export const PopupInner = styled.div`
  position: absolute;
  left: 25%;
  right: 25%;
  top: 0;
  bottom: auto;
  width: 642px;
  background: #f8f9fa;
  border-radius: 5px;
  margin: 68px 0 80px;
  padding-bottom: 30px;
  z-index: 200;
`;

export const Top = styled.div`
  padding: 0 8px 8px 28px;
  display: flex;
  flex-direction: row;
  margin: 30px 16px 8px 0;

  a {
    margin-top: -10px;

    img {
      width: 18px;
      height: 18px;
    }
  }

  img {
    width: 23px;
    height: 18px;
    margin: 3px 0 auto 2px;
  }
  h1 {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #444444;
    margin-left: 21px;
    margin-bottom: 12px;
    padding-right: 5%;
    width: 100%;
  }
`;

export const Main = styled.div`
  margin: 0;
  min-height: 24px;
  padding: 0 8px 8px 24px;
  position: relative;
  width: 100%;
  float: left;

  li {
    padding: 6px 14.32px;
  }

  #div {
    display: flex;
    flex-direction: row;
    margin-left: 51px !important;
    margin-top: 5px;
  }
  #icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
  }
  #options {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #777777;
  }
  #newService {
    padding-top: 30px;
    margin: 0 !important;
  }
  #tabList {
    padding-left: 41px;
    margin: 0 27px 25px 0;
  }
  #tab {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #495057;
  }
`;

export const Requester = styled.div`
  display: flex;
  flex-direction: row;
  #inputs {
    flex-direction: row;
    margin: 0;
    width: 100%;
  }
  #input {
    width: 260px;
    margin: 0 15px 5px 0;
  }
  #address {
    width: 535px;
    margin: 0 15px 24px 0;
  }
  img {
    width: 30px;
    height: 30px;
  }
  div {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
  }

  h1 {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #777777;
  }
  h2 {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #777777;
    margin-bottom: 6px;
  }
  input {
    width: 100%;
    height: 32px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 3px;

    padding-top: 2px;
    padding-left: 11px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;

    &:focus {
      outline: none;
      &::placeholder {
        color: transparent;
      }
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.35);
    }
  }

  select {
    width: 100%;
    height: 32px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 3px;

    padding-top: 2px;
    padding-left: 11px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;
    &:focus {
      outline: none;
      &::placeholder {
        color: transparent;
      }
    }
  }
  option {
    height: 32px;
    border: none;
  }
`;

export const ImgDescrip = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 31px;
  margin-left: 20px;

  #descImg {
    object-fit: contain;
    width: 106px;
    border-radius: 5px;

  }

  #descDiv {
    flex-direction: row;
    margin-left: 0;
  }
  div {
    display:flex;
    flex-direction: column;
    margin-left: 21px;
    width: 440px;
  }


  p {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: justify;
    margin-right: -59px;
    color: #000000;
    width: 368px;
    margin-bottom: 10px;
    word-wrap: break-word;
    margin-left: 25px;

    }
  }
`;

export const Attachments = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0;
  width: 95%;
  #img {
    width: 23px;
    height: 23px;
    margin-left: 3px;
  }

  h1 {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
    margin: 2px auto 11px 0;
  }

  div {
    margin: 0;
  }

  #body {
    margin-left: 21px !important;
    width: 100%;
  }

  hr {
    width: 100%;
    margin-top: 6px !important;
    margin-bottom: 25px !important;
  }
`;

export const AttachList = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -12px !important;
  img {
    object-fit: cover;
    width: 62px;
    height: 62px;
    filter: none;
    background-color: #fff;
  }
  div {
    margin: 0 !important;
  }
`;

export const Responsibles = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 37px;

  #img {
    width: 23px;
    height: 23px;
    margin-left: 3px;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 21px;
  }

  h1 {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }

  #add {
    width: 30px;
    height: 30px;
    margin-top: -1px;
    margin-left: -1px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 23px;

  img {
    margin-top: 4px;
    width: 23px;
    height: 15px;
    margin-left: 3px;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 21px;
    width: 540px;
  }

  h1 {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
    margin-bottom: 7px;
  }

  textarea {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #777777;
    margin-bottom: 24px;
    min-height: 98px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 11px;
    width: 533px;
    text-align: justify;
    text-justify: inter-word;

    &:focus {
      outline: none;
      &::placeholder {
        color: transparent;
      }
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.35);
      font-size: 12px;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  height: 32px;
  width: 533px;
  background: #444444;
  margin-left: 48px;
  border-radius: 3px;
  border: 0;
  align-items: center;

  div {
    display: flex;
    margin: auto;
    flex-direction: row;
  }

  img {
    width: 19px;
    height: 19px;
    margin-left: 7px;
  }
  p {
    width: 104px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-size: 12px;
    color: #ffffff;
    margin: 0;
  }

  &:hover,
  &:focus {
    color: #495057;
    background-color: #dae0e5 !important;
    border: none;
    outline: none;
  }

  &:active {
    background-color: #9ae0e5 !important;
  }
`;

export const Restriction = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;

  img {
    width: 30px;
    height: 30px;
    margin-left: -4px;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 22px;
  }

  h1 {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #777777;
    margin-bottom: 11px;
  }

  #body {
    flex-direction: row;
    margin-left: 0;
  }

  #titles {
    margin-left: 0;
  }
  #descriptions {
    margin-left: 11px;
    width: 392px;
  }

  p {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    width: 392px;

    color: #777777;
    margin-bottom: 7px;
    padding-right: 21px;
  }
  b {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;

    color: #777777;
    margin-bottom: 7px;
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 30px;

  h1 {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;

    color: #3bb54a;
    margin: auto;
    margin-right: 10px;
  }

  img {
    width: 39px;
    height: 39px;
    margin: auto;
    margin-left: 0;
  }
`;
