import React from 'react';

// import { Container } from './styles';
import ContentLeft from './components/ContentLeft';
import ContentRight from './components/ContentRight';
import ContentCenter from './components/ContentCenter';

class Teste extends React.Component {
  render() {
    return (
      <>
        <ContentLeft />
        <ContentCenter />
        <ContentRight />
      </>
    );
  }
}

export default Teste;
