import React from 'react';
import { Link } from 'react-router-dom';

import {
  PopupContainer,
  PopupInner,
  Top,
  Main,
  Margin,
  Requester,
  Input,
  DescriptionBody,
  Button,
} from './styles';

import Close from './img/close.png';
import Add from './img/add.png';

class SubPopup extends React.Component {
  render() {
    return (
      <PopupContainer
        /** Fecha popup em caso de cliques fora do conteudo central */
        onClick={this.props.closePopup}
      >
        <PopupInner
          /** Impede que cliques em PopupInner fechem o container */
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Top>
            <h1>{this.props.Title}</h1>
            <Link to="#" id="link">
              <img
                id="close"
                src={Close}
                alt="Close"
                onClick={this.props.closePopup}
              />
            </Link>
          </Top>
          <Margin />
          <Main>
            <Requester>
              <h1>Pessoas</h1>
              <div>
                <img src={Add} alt="" />
                <Input placeholder="Tempo total estimado(h)" id="input1" />
              </div>
              <a href="/">Adicionar outro</a>
            </Requester>
            <Requester>
              <h1>Equipamentos</h1>
              <div>
                <img src={Add} alt="" />
                <Input placeholder="Tempo de setup (h)" id="input2" />
                <Input placeholder="Tempo de operação(h)" id="input3" />
              </div>
              <a href="/">Adicionar outro</a>
            </Requester>
            <Requester>
              <h1>Insumos</h1>
              <div>
                <img src={Add} alt="" />
                <Input placeholder="Quantidade" id="input4" />
              </div>
              <a href="/">Adicionar outro</a>
            </Requester>
            <DescriptionBody>
              <Button onClick={this.props.budgetSuccess}>
                <p>Gerar orçamento</p>
              </Button>
            </DescriptionBody>
          </Main>
        </PopupInner>
      </PopupContainer>
    );
  }
}

export default SubPopup;
