import styled from 'styled-components';

export const PopupContainer = styled.div`
  position: fixed;
  width: 342px;
  height: 384px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 200;
`;
export const PopupInner = styled.div`
  position: absolute;
  left: 330px;
  top: 100px;
  bottom: auto;
  width: 100%;
  z-index: 300;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const Success = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px;

  h1 {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;

    color: #3bb54a;
    margin: auto;
    margin-right: 10px;
  }
  img {
    width: 39px;
    height: 39px;
    margin: auto;
    margin-left: 0;
  }
`;
