import React from 'react';
import { Title, BodyTeam } from './styles';

import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import CentralCard04 from '../../../../components/CentralCard04';

import Avatar1 from '../../../Team/img/avataaars 1.png';
import Avatar2 from '../../../Team/img/avataaars (2) 1.png';
import Avatar3 from '../../../Team/img/avataaars (3) 1.png';
import Avatar4 from '../../../Team/img/avataaars (1) 1.png';
import Avatar5 from '../../../Team/img/avataaars (4) 1.png';
import Avatar6 from '../../../Team/img/avataaars (6) 1.png';
import Avatar7 from '../../../Team/img/avataaars (5) 1.png';
import Avatar8 from '../../../Team/img/avataaars (7) 1.png';
import Avatar9 from '../../../Team/img/avataaars (11) 1.png';
import Avatar10 from '../../../Team/img/avataaars (9) 1.png';
import Avatar11 from '../../../Team/img/avataaars (10) 1.png';
import Avatar12 from '../../../Team/img/avataaars (8) 1.png';
import Avatar13 from '../../../Team/img/avataaars (12) 1.png';
import Avatar14 from '../../../Team/img/avataaars (14) 1.png';
import Avatar15 from '../../../Team/img/avataaars (13) 1.png';

export default function Team() {
  return (
    <BodyTeam>
      <Title />
      <CentralListOfCategories01 title="Equipe Atual">
        <CentralListOfCategories02 title="Professores">
          <CentralCard04
            title="Antônio de Souza"
            imgSrc={Avatar1}
            imgAlt="Microscope"
            route="/template-page-03"
          />
          <CentralCard04
            title="Júlia Andrade"
            imgSrc={Avatar2}
            imgAlt="Microscope"
            route="/template-page-03"
          />
        </CentralListOfCategories02>
        <CentralListOfCategories02 title="Pós-doutorandos">
          <CentralCard04
            title="Andressa Mello"
            imgSrc={Avatar3}
            imgAlt="Microscope"
            route="/template-page-03"
          />
          <CentralCard04
            title="Danilo Gondin"
            imgSrc={Avatar4}
            imgAlt="Microscope"
            route="/template-page-03"
          />
        </CentralListOfCategories02>
        <CentralListOfCategories02 title="Doutorandos">
          <CentralCard04
            title="Larissa Pereira"
            imgSrc={Avatar5}
            imgAlt="Microscope"
            route="/template-page-03"
          />
          <CentralCard04
            title="Bruna Bastos"
            imgSrc={Avatar6}
            imgAlt="Microscope"
            route="/template-page-03"
          />
        </CentralListOfCategories02>
        <CentralListOfCategories02 title="Mestrandos">
          <CentralCard04
            title="Júlio Amorim"
            imgSrc={Avatar7}
            imgAlt="Microscope"
            route="/template-page-03"
          />
          <CentralCard04
            title="Raissa Nakamura"
            imgSrc={Avatar8}
            imgAlt="Microscope"
            route="/template-page-03"
          />
          <CentralCard04
            title="Nelson Nagem"
            imgSrc={Avatar9}
            imgAlt="Microscope"
            route="/template-page-03"
          />
          <CentralCard04
            title="Paula Oliveira"
            imgSrc={Avatar10}
            imgAlt="Microscope"
            route="/template-page-03"
          />
        </CentralListOfCategories02>
        <CentralListOfCategories02 title="Graduandos">
          <CentralCard04
            title="Lucas Nogueira"
            imgSrc={Avatar11}
            imgAlt="Microscope"
            route="/template-page-03"
          />
          <CentralCard04
            title="Nathan Nunes"
            imgSrc={Avatar12}
            imgAlt="Microscope"
            route="/template-page-03"
          />
          <CentralCard04
            title="Luana Lacerda"
            imgSrc={Avatar13}
            imgAlt="Microscope"
            route="/template-page-03"
          />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
      <CentralListOfCategories01 title="Alumni">
        <CentralListOfCategories02 title="2019">
          <CentralCard04
            title="Felipe Pedroso"
            imgSrc={Avatar14}
            imgAlt="Microscope"
            route="/template-page-03"
          />
          <CentralCard04
            title="Alessandra Franco"
            imgSrc={Avatar15}
            imgAlt="Microscope"
            route="/template-page-03"
          />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
    </BodyTeam>
  );
}
