import React from 'react';
import { Link } from 'react-router-dom';

import {
  PopupContainer,
  PopupInner,
  Top,
  Main,
  SideBar,
  Requester,
  SideCard,
  Description,
  DescriptionBody,
  Input,
  Button,
  Equipment,
  EquipmentList,
  Attachments,
  AttachList,
  Responsibles,
  AtvHead,
  Comments,
  AddBy,
  Success,
} from './styles';

import Close from './img/close.png';
import Minimize from './img/minimize.png';
import Avatar from './img/avatar.png';
import Clock from './img/clock.png';
import Box from './img/box.png';
import Coin from './img/coin.png';
import Coment from './img/Group 47.png';
import Mail from './img/email 1.png';
import MicElet from './img/microscopy-nsit0108-01 10.png';
import Graphic from './img/eds_spectrum 2.png';
import Gear from './img/settings-gears 1.png';
import Attach from './img/attach.png';
import Word from './img/word.png';
import Excel from './img/excel.png';
import Bolt from './img/bolt.png';
import RespoIcon from './img/responsible.png';
import Add from './img/add.png';
import Atv from './img/atv.png';
import Avatar1 from './img/avatar1.png';
import Avatar2 from './img/avatar2.png';
import Icon from './img/icon.png';
import Tick from './img/tick.png';
import Pdf from './img/pdf.png';

import CentralCard04 from '../CentralCard04';
import CentralCard01 from '../CentralCard01';
import SubPopup from '../SubPopup';
import Budget from '../Budget';

class Popup extends React.Component {
  constructor() {
    super();
    this.state = {
      showSubPopup: false,
      show: true,
      budget: false,
      pdf: false,
    };
  }

  toggleSubPopup() {
    this.setState({
      showSubPopup: !this.state.showSubPopup,
    });
  }

  async closePop() {
    const promise = new Promise((resolve) => {
      setTimeout(() => resolve('/teste'), 2000);
    });
    const result = await promise;
    window.location.replace(result);
  }

  // Close the success messege
  async closeMes() {
    const promise = new Promise((resolve) => {
      setTimeout(() => resolve(false), 2000);
    });
    const result = await promise;
    this.setState({
      budget: result,
    });
  }

  selectPop() {
    this.setState({
      show: !this.state.show,
    });
    this.closePop();
  }

  budgetSuccess() {
    this.setState({
      budget: !this.state.budget,
      pdf: true,
    });
    this.toggleSubPopup();
    this.closeMes();
  }

  render() {
    if (this.state.show) {
      return (
        <PopupContainer
          /** Fecha popup em caso de cliques fora do conteudo central */
          onClick={this.props.closePopup}
        >
          <PopupInner
            /** Impede que cliques em PopupInner fechem o container */
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Top>
              <img src={Minimize} alt="" />
              <h1>{this.props.Title}</h1>
              <Link to="#">
                <img
                  id="close"
                  src={Close}
                  alt="Close"
                  onClick={this.props.closePopup}
                />
              </Link>
            </Top>
            <Main>
              <Requester>
                <img src={Avatar} alt="" />
                <div>
                  <h1>SOLICITANTE</h1>
                  <h2>Nome</h2>
                  <p>Paulo</p>
                  <h2>Email</h2>
                  <p>paulo.mendonca@hess-engenharia.com.br</p>
                  <h2>Cargo</h2>
                  <p>Analista de Projetos Mecânicos</p>
                  <h2>Organização</h2>
                  <p>Hess Engenharia</p>
                  <h2>Endereço</h2>
                  <p>
                    Rua Arnoldo Schlemper, s/n°, Jd.Eldorado CEP 88133-307
                    Palhoça, SC - Brasil
                  </p>
                </div>
              </Requester>
              <Description>
                <img src={Coment} alt="" />
                <div>
                  <h1>Descrição</h1>
                  <p>
                    Estamos realizando experimentos para desenvolver um novo
                    elemento de fixação e precisamos avaliar a composição
                    química desse componente ao longo de uma sequência de
                    ensaios para identificar se está havendo degradação do
                    material. A análise precisa ser não-destrutiva e estamos
                    particularmente interessados em mensurar o grau de oxidação
                    do elemento ao longo dos experimentos.
                  </p>
                </div>
              </Description>
              <DescriptionBody>
                <Input type="text" placeholder="Escreva uma resposta..." />
                <Button onClick={this.selectPop.bind(this)}>
                  <img src={Mail} alt="" />
                  <p>Responder por email</p>
                </Button>
              </DescriptionBody>
              <Equipment>
                <img src={Gear} alt="" />
                <div>
                  <h1>Equipamentos e serviços pré-selecionados</h1>
                </div>
              </Equipment>
              <EquipmentList>
                <CentralCard04
                  imgSrc={MicElet}
                  imgAlt=""
                  title="Microscópio eletrônico de varredura"
                />
                <CentralCard04
                  imgSrc={Graphic}
                  imgAlt=""
                  title="Análise de composição química"
                />
              </EquipmentList>
              <Attachments>
                <img src={Attach} alt="" id="img" />
                <div id="body">
                  <h1>Anexos</h1>
                  <AttachList>
                    <CentralCard01
                      title="Detalhe.doc"
                      imgSrc={Word}
                      imgAlt="Detalhe.doc"
                      route="#"
                    />
                    <CentralCard01
                      title="Planilha.doc"
                      imgSrc={Excel}
                      imgAlt="Planilha.xls"
                      route="#"
                    />
                    <CentralCard01
                      title="Parafuso.jpg"
                      imgSrc={Bolt}
                      imgAlt="Parafuso.jpg"
                      route="#"
                    />
                    {this.state.pdf ? (
                      <CentralCard01
                        title="Orçamento.pdf"
                        imgSrc={Pdf}
                        imgAlt="Parafuso.jpg"
                        route="#"
                      />
                    ) : null}
                  </AttachList>

                  <hr />
                </div>
              </Attachments>
              <Responsibles>
                <img src={RespoIcon} alt="" id="img" />
                <div>
                  <h1>Reponsáveis</h1>
                  <img src={Add} alt="" id="add" />
                </div>
              </Responsibles>
              <AtvHead>
                <img src={Atv} alt="" />
                <h1>Atividade</h1>
              </AtvHead>
              <Comments>
                <div>
                  <img src={Avatar1} alt="" />
                  <input placeholder="Escreva um comentário..." />
                </div>
                <div>
                  <img src={Avatar2} alt="" />
                  <p>
                    <b>@antoniodesouza</b>, o MEV está em manutenção até a
                    próxima terça-feira (22/06/2020). Além disso informe ao
                    solicitante que as dimensões máximas do componente precisam
                    ser menores ou iguais a 70mm x 100mm x 100mm para caber no
                    equipamento.
                  </p>
                </div>
              </Comments>
              <div id="div">
                <img src={Icon} alt="" id="icon" />
                <p id="options">
                  {' '}
                  - <u>Responder</u> - <u>Encaminhar</u>
                </p>
              </div>
              <AddBy>
                <img src={Avatar} alt="" />
                <div>
                  <p>
                    Criado por <b>Paulo</b>
                  </p>
                  <h3>10 de Junho às 13:33h</h3>
                </div>
              </AddBy>
            </Main>

            <SideBar>
              <h1>ADICIONAR</h1>
              <SideCard>
                <img src={Clock} alt="" />
                <p>Prazo de entrega</p>
              </SideCard>
              <SideCard>
                <img src={Box} alt="" />
                <p>Insumos relacionados</p>
              </SideCard>
              <SideCard onClick={this.toggleSubPopup.bind(this)}>
                <img src={Coin} alt="" />
                <p>Orçamentos</p>
              </SideCard>
              {this.state.showSubPopup && !this.state.budget ? (
                <SubPopup
                  Title="Orçamento"
                  closePopup={this.toggleSubPopup.bind(this)}
                  budgetSuccess={this.budgetSuccess.bind(this)}
                />
              ) : null}
              {this.state.budget ? <Budget /> : null}
            </SideBar>
          </PopupInner>
        </PopupContainer>
      );
    }
    if (!this.state.show) {
      return (
        <PopupContainer>
          <PopupInner>
            <Success>
              <h1>Email enviado com sucesso.</h1>
              <img src={Tick} alt="" />
            </Success>
          </PopupInner>
        </PopupContainer>
      );
    }
  }
}

export default Popup;
