import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  margin-right: 2rem;
  flex-direction: column;
  #services {
    margin-top: 10px;
    margin-right: 10px;
  }
  #equipment {
    display: inline-table;
    min-height: 208.1px;
    margin-top: 10px;
    margin-right: 10px;
  }
  #equipContent {
    padding-bottom: 10px;
  }
  #expandBnt {
    padding: 0;
  }
  #action {
    flex-direction: column;
    height: 100%;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Title = styled.h2`
  max-width: 100%;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  color: #777;
  margin: 0;
  padding-left: 0.25rem;
`;
