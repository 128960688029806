import React from 'react';

// import { Container } from './styles';
import ContentLeft from './components/ContentLeft';
import ContentCenter from './components/ContentCenter';

export default function CermatPage() {
  return (
    <>
      <ContentLeft />
      <ContentCenter />
    </>
  );
}
