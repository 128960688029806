import React from 'react';
import { Input } from './styles';

export default class Find extends React.Component {
  keyPressed(event) {
    if (event.key === 'Enter') {
      window.location.replace('/page-01');
    }
  }

  render() {
    return (
      <Input
        type="text"
        placeholder="Digite o que procura"
        onKeyPress={this.keyPressed}
      />
    );
  }
}
