import React from 'react';
import { Title, BodyTeam } from './styles';

import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import TeamCard from '../../../../components/TeamCard';

import Avatar1 from '../../../Team/img/avataaars 1.png';
import Avatar2 from '../../../Team/img/avataaars (2) 1.png';
import Avatar3 from '../../../Team/img/avataaars (3) 1.png';
import Avatar4 from '../../../Team/img/avataaars (1) 1.png';
import Avatar5 from '../../../Team/img/avataaars (4) 1.png';
import Avatar6 from '../../../Team/img/avataaars (6) 1.png';
import Avatar7 from '../../../Team/img/avataaars (5) 1.png';
import Avatar8 from '../../../Team/img/avataaars (7) 1.png';
import Avatar9 from '../../../Team/img/avataaars (11) 1.png';
import Avatar11 from '../../../Team/img/avataaars (10) 1.png';
import Avatar12 from '../../../Team/img/avataaars (8) 1.png';
import Avatar13 from '../../../Team/img/avataaars (12) 1.png';
import Avatar14 from '../../../Team/img/avataaars (14) 1.png';
import Avatar15 from '../../../Team/img/avataaars (13) 1.png';

export default function Team() {
  return (
    <BodyTeam>
      <Title />
      <CentralListOfCategories01 title="Equipe Atual">
        <CentralListOfCategories02 title="">
          <TeamCard
            name="Antônio de Souza"
            ImgSrc={Avatar1}
            description="Professor"
          />
          <TeamCard
            name="Júlia Andrade"
            ImgSrc={Avatar2}
            description="Professora"
          />

          <TeamCard
            name="Andressa Mello"
            ImgSrc={Avatar3}
            description="Pós-doutoranda"
          />
          <TeamCard
            name="Danilo Gondin"
            ImgSrc={Avatar4}
            description="Pós-doutorando"
          />

          <TeamCard
            name="Larissa Pereira"
            ImgSrc={Avatar5}
            description="Doutoranda"
          />
          <TeamCard
            name="Bruna Bastos"
            ImgSrc={Avatar6}
            description="Doutoranda"
          />

          <TeamCard
            name="Júlio Amorim"
            ImgSrc={Avatar7}
            description="Mestrando"
          />
          <TeamCard
            name="Raissa Nakamura"
            ImgSrc={Avatar8}
            description="Mestranda"
          />
          <TeamCard
            name="Nelson Nagem"
            ImgSrc={Avatar9}
            description="Mestrando"
          />

          <TeamCard
            name="Lucas Nogueira"
            ImgSrc={Avatar11}
            description="Graduando"
          />
          <TeamCard
            name="Nathan Nunes"
            ImgSrc={Avatar12}
            description="Graduando"
          />
          <TeamCard
            name="Luana Lacerda"
            ImgSrc={Avatar13}
            description="Graduanda"
          />

          <TeamCard
            name="Felipe Pedroso"
            ImgSrc={Avatar14}
            description="Alumni 2019"
          />
          <TeamCard
            name="Helena Franco"
            ImgSrc={Avatar15}
            description="Alumni 2019"
          />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
    </BodyTeam>
  );
}
