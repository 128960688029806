import React from 'react';
import CardModel1 from './CardModel1';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import { Body, Title } from './styles';

import Normas from '../../img/normas.jpg';
import Falhas from '../../img/falhas.jpg';
import AnaliMat from '../../img/analimat.JPG';
import Laudos from '../../img/laudos.jpg';
import Grao from '../../img/grao.jpg';
import Poros from '../../img/poros.jpg';
import Tenacidade from '../../img/tenacidade.jpg';
import Dureza from '../../img/dureza.jpg';
import AnaliTerm from '../../img/choquetermico.jpg';
// import { Container } from './styles';

export default function Services() {
  return (
    <Body>
      <Title />

      <CentralListOfCategories01 title="Análise">
        <CentralListOfCategories02 title="Análise microestrutural">
          <CardModel1
            id="card1"
            imgSrc={Grao}
            title=" Distribuição de tamanho de grão (pó e
                  microestrutural)"
          />
          <CardModel1
            id="card1"
            imgSrc={Poros}
            title="
                Densidade e porosidade"
          />
        </CentralListOfCategories02>
        <CentralListOfCategories02 title="Análise microestrutural">
          <CardModel1
            id="card1"
            imgSrc={Tenacidade}
            title="Tenacidade à fratura (KIC VIA ICL)
                "
          />
          <CardModel1
            id="card1"
            imgSrc={Dureza}
            title="Dureza (ROCKWELL C, VICKERS, SHORE A E D)
                "
          />
        </CentralListOfCategories02>
        <CentralListOfCategories02 title="Outras análises">
          <CardModel1 id="card1" imgSrc={AnaliTerm} title="Análises térmicas" />
          <CardModel1 id="card1" imgSrc={Falhas} title=" Análise de falhas" />
          <CardModel1
            id="card1"
            imgSrc={AnaliMat}
            title=" Avaliação e análise de matérias-primas"
          />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
      <CentralListOfCategories01 title="Consultoria e projeto">
        <CentralListOfCategories02 title="Consultoria ">
          <CardModel1
            id="card1"
            imgSrc={Normas}
            title="Interpretação de normas técnicas"
          />

          <CardModel1 id="card1" imgSrc={Laudos} title=" Laudos técnicos" />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
    </Body>
  );
}
