import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;

  @media (min-width: 768px) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const Title = styled.div`
  padding: 0.5rem 0.5rem;
  color: #495057;
  font-size: 1rem;
  font-weight: 500;
  border-bottom: 2px solid #ddff00;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
`;

export const BodyTeam = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  img {
    filter: none;
  }
`;
export const Image = styled.img`
  width: 120px;
  height: 120px;
  background: #ffffff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const ContainerTop = styled.div`
  #List {
    width: 100%;
    padding-left: 152px;
    margin-bottom: 40px;
  }
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  padding-top: 18px;
  padding-bottom: 14px;
  align-items: center;
`;
