import React from 'react';
import {
  Requester,
  Description,
  Attachments,
  AttachList,
  Button,
} from './styles';

import Avatar from './img/avatar.png';
import Attach from './img/attach.png';
import Add from './img/Frame 6.png';
import Coment from './img/Group 47.png';
import Send from './img/send.png';

import CentralCard01 from '../CentralCard01';

export default class Solicitation extends React.Component {
  render() {
    return (
      <>
        <Requester>
          <img src={Avatar} alt="" />
          <div>
            <h1>SOLICITANTE</h1>
            <div id="inputs">
              <div id="input">
                <h2>Nome:</h2>
                <input placeholder="Ex: Luciano Cardoso" />
              </div>
              <div id="input">
                <h2>Email:</h2>
                <input placeholder="Ex: seu-email@email.com" />
              </div>
            </div>
            <div id="inputs">
              <div id="input">
                <h2>Cargo:</h2>
                <input placeholder="Ex: Analista de Projetos Mecânicos" />
              </div>
              <div id="input">
                <h2>Organização:</h2>
                <input placeholder="Ex: Alvez Engenharia Ltda." />
              </div>
            </div>
            <div id="inputs">
              <div id="input">
                <h2>Cidade:</h2>
                <input placeholder="Ex: Florianópolis" />
              </div>
              <div id="input">
                <h2>Estado:</h2>
                <select>
                  <option value="SC">SC</option>
                  <option value="SC">RS</option>
                  <option value="SC">PR</option>
                </select>
              </div>
            </div>
            <div id="address">
              <h2>Endereço:</h2>
              <input placeholder="Ex.: Rua das Torres, 1254, Bairro Pantanal, CEP 88000-000" />
            </div>
          </div>
        </Requester>

        <Attachments>
          <img src={Attach} alt="" id="img" />
          <div id="body">
            <h1>Anexar Documentos</h1>
            <AttachList>
              <CentralCard01
                title="Novo Documento"
                imgSrc={Add}
                imgAlt=""
                route="#"
              />
            </AttachList>
          </div>
        </Attachments>
        <Description>
          <img src={Coment} alt="" />
          <div>
            <h1>Descrição</h1>
            <textarea
              placeholder="Ex.: Estamos realizando experimentos para desenvolver um novo elemento de
            fixação e precisamos avaliar a composição química desse componente
            ao longo de uma sequência de ensaios para identificar se está
            havendo degradação do material. A análise precisa ser não-destrutiva
            e estamos particularmente interessados em mensurar o grau de
            oxidação do elemento ao longo dos experimentos."
            />
          </div>
        </Description>
        <Button onClick={this.props.selectPop}>
          <div>
            <p>Enviar solicitação</p>
            <img src={Send} alt="" />
          </div>
        </Button>
      </>
    );
  }
}
