import React from 'react';
import { Body, Title } from './styles';
import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import CentralCard04 from '../../../../components/CentralCard04';

// import { Container } from './styles';

import Prep from '../../img/prep.png';
import Anali100x from '../../img/anali100x.png';
import Anali1000x from '../../img/anali1000x.png';
import AnaliMat from '../../img/mat.png';
import AnaliBio from '../../img/bio.png';
import Conf from '../../img/Conf.png';
import Fluor from '../../img/fluor.png';

function Services() {
  return (
    <Body>
      <Title />
      <CentralListOfCategories01 title="Análise">
        <CentralListOfCategories02 title="">
          <CentralCard04
            title="Preparação de amostras"
            imgSrc={Prep}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Análise de microscopia com ampliação de até 100x"
            imgSrc={Anali100x}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Análise de microscopia com amplicação de até 1000x"
            imgSrc={Anali1000x}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Análise de materiais (semicondutores, metais e cerâmicos)"
            imgSrc={AnaliMat}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Análise de sistemas orgânicos/biológicos e poliméricos"
            imgSrc={AnaliBio}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Microscopia confocal"
            imgSrc={Conf}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Microscopia de fluorescência"
            imgSrc={Fluor}
            imgAlt=""
            route="/template-page-03"
          />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
    </Body>
  );
}

export default Services;
