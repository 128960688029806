import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border: solid;
  border-color: #0f0;
  flex: 1 1 auto;
  overflow-x: hidden;
`;
