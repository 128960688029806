import React from 'react';
import { Container, Title, Body } from './styles';

import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import EquipmentCard from '../../../../components/EquipmentCard';

import Plasma from '../../img/plasmareact.png';
import Sintering from '../../img/SinteringFurnace.png';
import Tensile from '../../img/tensile.png';
import Twist from '../../img/twist.png';
import Charpy from '../../img/charpy.png';
import Tribometro from '../../img/tribometro.jpg';
import Mev from '../../img/mev.png';
import Espectometro from '../../img/espectometro.png';
import Injetora from '../../img/injetora.png';

export default function ContentCenter() {
  return (
    <Container>
      <Title />
      <Body>
        <CentralListOfCategories01 title="Análise">
          <CentralListOfCategories02 title="Ensaios mecânicos">
            <EquipmentCard
              title="Maquina de ensaio de tração"
              imgSrc={Tensile}
              imgAlt=""
              text="Realiza ensaios com carga de até 500KN"
            />
            <EquipmentCard
              title="Máquina de ensaio de torção"
              imgSrc={Twist}
              imgAlt=""
              text="Capaz de realizar de maneira rápida, eficaz e precisa a analise de torque e torção de diversos tipos de amostras e corpos de prova"
            />
            <EquipmentCard
              title="Maquina de ensaio charpy"
              imgSrc={Charpy}
              imgAlt=""
              text="Realiza testes de resiliência em materiais"
            />
            <EquipmentCard
              title="Tribômetro de pino"
              imgSrc={Tribometro}
              imgAlt=""
              text="Equipamento utilizado para analizar o comportamento tribologico dos materiais"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Ensaios ópticos">
            <EquipmentCard
              title="Microscópio eletronico de varredura"
              imgSrc={Mev}
              imgAlt=""
              text="O MEV é empregado na caracterização superficial de amostras"
            />
            <EquipmentCard
              title="Espectometro de massa"
              imgSrc={Espectometro}
              imgAlt=""
              text="Detecta e identifica moléculas de interesse por meio da medição da sua massa e da caracterização de sua estrutura química"
            />
          </CentralListOfCategories02>
        </CentralListOfCategories01>
        <CentralListOfCategories01 title="Fabricação">
          <CentralListOfCategories02 title="Processos elétricos">
            <EquipmentCard
              title="Reator a plasma"
              imgSrc={Plasma}
              imgAlt=""
              text="Equipamento capaz de realizar tratamentos termicos de nitretação, cementação e boretação"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Processos térmicos">
            <EquipmentCard
              title="Forno para sinterização a vácuo"
              imgSrc={Sintering}
              imgAlt=""
              text="Realiza processos de sinterização de pó metálico"
            />
            <EquipmentCard
              title="Injetora de polímeros"
              imgSrc={Injetora}
              imgAlt=""
              text="Injeção de polímeros e pós metálicos em matrizes"
            />
          </CentralListOfCategories02>
        </CentralListOfCategories01>
      </Body>
    </Container>
  );
}
