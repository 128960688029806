import React from 'react';
import { Container, Title, Body } from './styles';

import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import CentralCard04 from '../../../../components/CentralCard04';
import CentralCard05 from '../../../../components/CentralCard05';

import Equipe from '../../img/15167 1.png';
import Demandas from '../../img/image 4.png';
import Recursos from '../../img/nuts-bolts-washers-set 2.png';
import Add from '../../img/Frame 6.png';
import AlAl2Cu from '../../img/image 10.png';
import Graphic1 from '../../img/image 11.png';
import Graphic2 from '../../img/image 21.png';
import Fresa from '../../img/image 24.png';
import Image from '../../img/image 26.png';
import Motor from '../../img/image 27.png';
import Turbina from '../../img/turbine_CAD 2.png';
import Avatar1 from '../../img/avataaars (1) 1.png';
import Avatar2 from '../../img/avataaars (2) 1.png';
import Avatar3 from '../../img/avataaars 1.png';
import Avatar4 from '../../img/avataaars (3) 1.png';
import LMP from '../../img/image 13.png';
import LOGO from '../../img/logo-site1_opt-1 2.png';
import WEG from '../../img/image 25.png';
import LabMat from '../../img/image 22.png';
import Cermat from '../../img/image 23.png';
import LabSolda from '../../img/dest_labsolda 3.png';

export default function ContentCenter() {
  return (
    <Container>
      <Title>Meu painel</Title>
      <Body>
        <CentralListOfCategories01 title="Menu principal">
          <CentralListOfCategories02 title="Resumo">
            <CentralCard04
              title="Equipe"
              imgSrc={Equipe}
              imgAlt="Microscope"
              route="/equipe"
            />
            <CentralCard04
              title="Demandas"
              imgSrc={Demandas}
              imgAlt="Microscope"
              route="/demandas"
            />
            <CentralCard04
              title="Recursos"
              imgSrc={Recursos}
              imgAlt="Microscope"
              route="/recursos"
            />
            <CentralCard04
              title="Adicionar novo tópico"
              imgSrc={Add}
              imgAlt="Microscope"
              route="/dashboard"
            />
          </CentralListOfCategories02>
        </CentralListOfCategories01>
        <CentralListOfCategories01 title="Atividades recentes">
          <CentralListOfCategories02 title="Pesquisa">
            <CentralCard05
              route="/template-page-03"
              imgSrc1={AlAl2Cu}
              imgSrc2={Graphic1}
              imgSrc3={Avatar3}
              imgSrc4={Avatar1}
              imgSrc5={Avatar2}
              imgSrc6={Cermat}
              imgSrc7={LabMat}
              imgSrc8={LMP}
              text1="Tratamento superficial de amostras de NiCrMo por refusão a laser de fibra."
              text2="Última atualização por Júlia, Ontem."
            />
            <CentralCard05
              route="/template-page-03"
              imgSrc1={Graphic2}
              imgSrc3={Avatar3}
              imgSrc4={Avatar4}
              imgSrc7={LabSolda}
              imgSrc8={LMP}
              text1="Caracterização superficial de componentes fabricados em gravidade zero."
              text2="Última atualização por Andressa, Ontem."
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Extensão">
            <CentralCard05
              route="/template-page-03"
              imgSrc1={Turbina}
              imgSrc2={Fresa}
              imgSrc3={Avatar3}
              imgSrc4={Avatar2}
              imgSrc6={LOGO}
              imgSrc7={LMP}
              text1="Projeto e fabricação de turbina de aço inoxidável protótipo para hidrelétrica Itaipú."
              text2="Última atualização por você, 11/06/2020 13:39h."
            />
            <CentralCard05
              route="/template-page-03"
              imgSrc1={Motor}
              imgSrc2={Image}
              imgSrc3={Avatar3}
              imgSrc4={Avatar1}
              imgSrc6={WEG}
              imgSrc7={LMP}
              text1="Medição de microdureza em amostras de mancal WEG."
              text2="Última atualização por Danilo, 04/06/2020 11:20h."
            />
          </CentralListOfCategories02>
        </CentralListOfCategories01>
      </Body>
    </Container>
  );
}
