import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import AppWrapper from '../components/AppWrapper';
import { FlexNavbar, AuthFlexNavbar } from '../components/FlexNavbar';
import ContentWrapper from '../components/ContentWrapper';
import FlexFooter from '../components/FlexFooter';
/**
 * Wrapper para Route do react-router-dom
 * Faz as devidas validações antes do usuário acessar a página
 */
export default function RouteWrapper({
  component: Component,
  isSign,
  isPrivate,
  ...rest
}) {
  // caso a rota esteja marcada, retorna o componente acessado sem NavBar e Footer
  if (isSign) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <AppWrapper>
            <ContentWrapper>
              <Component {...props} />
            </ContentWrapper>
          </AppWrapper>
        )}
      />
    );
  }

  // retorna o componente acessado com NavBar para usuários autenticados e Footer
  if (isPrivate) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <AppWrapper>
            <AuthFlexNavbar />
            <ContentWrapper>
              <Component {...props} />
            </ContentWrapper>
            <FlexFooter />
          </AppWrapper>
        )}
      />
    );
  }

  // retorna o componente acessado com NavBar e Footer
  return (
    <Route
      {...rest}
      render={(props) => (
        <AppWrapper>
          <FlexNavbar />
          <ContentWrapper>
            <Component {...props} />
          </ContentWrapper>
          <FlexFooter />
        </AppWrapper>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isSign: PropTypes.bool,
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isSign: false,
  isPrivate: false,
};
