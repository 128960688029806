import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 540px;
  min-width: 300px;
  text-align: center;
  margin: 9% auto auto auto !important;

  img {
    object-fit: contain;
    width: 288px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 30px;
    flex-grow: 1;

    input {
      border: 1px solid #9aa5af;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #222;
      margin: 0 0 10px;

      &::placeholder {
        color: rgba(100, 100, 100, 0.7);
      }
    }
    div {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      margin-top: 8px;

      a {
        margin: auto 0 auto auto;
        font-size: 14px;
        color: #000;
      }
    }

    button {
      margin: 5px 0 0;
      height: 40px;
      background: #343a40;
      color: #df0;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      margin-left: 0;
      min-width: 150px;

      &:hover {
        background: ${darken(0.03, '#343a40')};
      }
    }

    a {
      color: #000;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
    hr {
      color: #000;
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.5);
      margin-top: 23px;
    }
  }
`;

export const Down = styled.div`
  margin-top: 7px !important;
  p {
    margin: auto;
    margin-right: 0;
    padding-right: 3px;
    color: #9aa5af;
  }
  #right {
    margin: auto 0 !important;
  }
  #left {
    margin: auto 0 !important;
  }
`;

export const Button = styled.a`
  margin: 5px 0 0;
  height: 40px;
  background: #343a40;
  color: #df0;
  text-align: center;
  vertical-align: center;
  justify-content: center;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;
  margin-left: 0;
  min-width: 150px;

  &:hover {
    background: ${darken(0.03, '#343a40')};
    text-decoration: none;
  }
`;
