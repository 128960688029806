import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Solicitation from './solicitation';

import {
  PopupContainer,
  PopupInner,
  Top,
  Main,
  ImgDescrip,
  Restriction,
  Success,
} from './styles';

import Close from './img/close.png';
import Minimize from './img/minimize.png';
import Science from './img/science.png';
import Task from './img/task.png';
import Tick from './img/tick.png';

class PopRequest extends React.Component {
  constructor() {
    super();
    this.state = {
      show: true,
    };
  }

  async closePop() {
    const promise = new Promise((resolve) => {
      setTimeout(() => resolve('/page-02'), 2000);
    });
    const result = await promise;
    window.location.replace(result);
  }

  selectPop() {
    this.setState({
      show: !this.state.show,
    });
    this.closePop();
  }

  render() {
    if (this.state.show) {
      return (
        <PopupContainer
          /** Fecha popup em caso de cliques fora do conteudo central */
          onClick={this.props.closePopup}
        >
          <PopupInner
            /** Impede que cliques em PopupInner fechem o container */
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Top>
              <img src={Minimize} alt="" />
              <h1>{this.props.Title}</h1>
              <Link to="#">
                <img
                  id="close"
                  src={Close}
                  alt="Close"
                  onClick={this.props.closePopup}
                />
              </Link>
            </Top>
            <Main>
              <ImgDescrip>
                <div>
                  <div id="descDiv">
                    <img src={this.props.imgSrc} id="descImg" alt="" />
                    <p>{this.props.Description}</p>
                  </div>
                </div>
              </ImgDescrip>

              <Tabs>
                <TabList id="tabList">
                  <Tab id="tab">Solicitação</Tab>
                  <Tab id="tab">Restrições</Tab>
                </TabList>
                <TabPanel>
                  <Solicitation selectPop={this.selectPop.bind(this)} />
                </TabPanel>
                <TabPanel>
                  <Restriction>
                    <img src={Science} alt="" />
                    <div>
                      <h1>Amostra</h1>
                      <div id="body">
                        <div id="titles">
                          <b>Classe de materiais:</b>
                          <b>Propriedade óptica:</b>
                          <b>Estado físico nas CNTP:</b>
                          <b>Dimensões mínimas:</b>
                          <b>Dimensões máximas:</b>
                        </div>
                        <div id="descriptions">
                          <p>Metais, cerâmicas e polímeros</p>
                          <p>Opaco</p>
                          <p>Sólido</p>
                          <p>10mm x 10mm x 10mm</p>
                          <p>50mm x 50mm x 100mm</p>
                        </div>
                      </div>
                    </div>
                  </Restriction>
                  <Restriction>
                    <img src={Task} alt="" />
                    <div>
                      <h1>Cuidados</h1>
                      <div id="body">
                        <div id="titles">
                          <b>Transporte:</b>
                          <b>Identificação do volume:</b>
                          <b>Identificação das amostras:</b>
                          <b>Armazenamento:</b>
                          <b>Endereço para entrega:</b>
                        </div>
                        <div id="descriptions">
                          <p>
                            As amostras devem ser enviadas em um mesmo volume
                            (ex.: Caixa).
                          </p>
                          <p>
                            A caixa deve estar identificada em nome do
                            solicitante.
                          </p>
                          <p>Todas as amostras devem estar numeradas.</p>
                          <p>
                            As amostras devem estar em saquinhos plásticos
                            individuais.
                          </p>
                          <p>
                            FEESC - Universidade Federal de Santa Catarina -
                            Campus Trindade - R. Delfino Conti, s/n - Trindade,
                            Florianópolis - SC, 88040-370
                          </p>
                        </div>
                      </div>
                    </div>
                  </Restriction>
                </TabPanel>
              </Tabs>
            </Main>
          </PopupInner>
        </PopupContainer>
      );
    }
    if (!this.state.show) {
      return (
        <PopupContainer>
          <PopupInner>
            <Success>
              <h1>Solicitação enviada com sucesso.</h1>
              <img src={Tick} alt="" />
            </Success>
          </PopupInner>
        </PopupContainer>
      );
    }
  }
}

export default PopRequest;
