import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled(Link)`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  height: 100% !important;

  margin: 0.75rem;
  text-decoration: none;
  width: 62px;
  word-break: normal;

  &:hover {
    text-decoration: none;
    transform: scaleX(1.025) scaleY(1.025);
  }
`;

export const Img = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

  filter: grayscale(1);
`;

export const Title = styled.p`
  font-size: 11px;
  color: #aaa;
  line-height: 1.15;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Body = styled.div`
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  align-self: center;
  padding: 0.25rem;
`;
