import React from 'react';
import { Container, Title, Body } from './styles';

import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import CentralCard01 from '../../../../components/CentralCard01';

import Furadeira from '../../../../img/manufacturing-macrovector_oficial-01 1.png';
import TornoVert from '../../../../img/manufacturing-macrovector_oficial-01 2.png';
import FresaConv1 from '../../../../img/manufacturing-macrovector_oficial-01 4.png';
import RetifPLan from '../../../../img/manufacturing-macrovector_oficial-01 7.png';
import TornoCNC1 from '../../../../img/manufacturing-macrovector-02-c 1.png';
import CorteLaser from '../../../../img/manufacturing-macrovector-02-c 9.png';
import Esteira from '../../../../img/manufacturing-microone-01 1.png';
import RetifBanc from '../../../../img/manufacturing-microone-01 4.png';
import Brac1 from '../../../../img/manufacturing-microone-01 2.png';
import Brac2 from '../../../../img/manufacturing-microone-01 3.png';
import Reat1 from '../../../../img/analysis-macrovector-01 9.png';
import Reat2 from '../../../../img/analysis-macrovector-01 11.png';
import Reat3 from '../../../../img/analysis-macrovector-01 10.png';
import Injetora from '../../../../img/manufacturing-microone-01 5.png';
import FresaConv2 from '../../../../img/manufacturing-macrovector-02-c 2.png';
import TornoConv2 from '../../../../img/image 1.png';
import Mandriladora from '../../../../img/manufacturing-macrovector_oficial-01 12.png';
import PrensaHidraulica from '../../../../img/manufacturing-macrovector-02-c 17.png';
import VacuumForming from '../../../../img/manufacturing-macrovector-02-c 14.png';
import Impressora3D from '../../../../img/image 78.png';
import FornoInducao from '../../../../img/manufacturing-macrovector-02-c 12.png';
import Mufla from '../../../../img/analysis-macrovector-02-b 29.png';
import TermoReator from '../../../../img/analysis-macrovector-02-b 25.png';

/**
import FurBanc2 from '../../../../img/image 3.png';
import FurBanc2 from '../../../../img/manufacturing-macrovector_oficial-01 5.png';
import FurBanc2 from '../../../../img/manufacturing-macrovector_oficial-01 6.png';
import FurBanc2 from '../../../../img/manufacturing-macrovector_oficial-02 1.png';
import FurBanc2 from '../../../../img/manufacturing-macrovector_oficial-02 2.png';
import FurBanc2 from '../../../../img/manufacturing-macrovector_oficial-01 4.png';
import FurBanc2 from '../../../../img/manufacturing-macrovector-02-c 3.png';
import FurBanc2 from '../../../../img/manufacturing-macrovector-02-c 4.png';
import FurBanc2 from '../../../../img/manufacturing-macrovector-02-c 6.png';
import FurBanc2 from '../../../../img/manufacturing-macrovector-02-c 7.png';
import FurBanc2 from '../../../../img/manufacturing-macrovector-02-c 8.png';
import FurBanc2 from '../../../../img/manufacturing-microone-01 6.png';
*/

import microscope from '../../img/microscope.png';
import CamTerm from '../../../../img/CamTerm3.png';
import CamVelo from '../../../../img/CamVelo4.png';
import CamDsrl from '../../../../img/CamDslr2.png';
import Vidraria from '../../../../img/analysis-macrovector-01 1.png';
import Decant from '../../../../img/analysis-macrovector-01 2.png';
import Balanca1 from '../../../../img/analysis-macrovector-01 3.png';
import Espect1 from '../../../../img/analysis-macrovector-01 4.png';
import CentrifTub from '../../../../img/analysis-macrovector-01 6.png';
import Analis1 from '../../../../img/analysis-macrovector-01 7.png';
import Evap from '../../../../img/analysis-macrovector-01 8.png';
import Analis2 from '../../../../img/analysis-macrovector-03 3.png';
import BancEns from '../../../../img/analysis-macrovector-03 2.png';
import Espect2 from '../../../../img/analysis-macrovector-02-b 7.png';
import Proveta from '../../../../img/analysis-macrovector-02-b 5.png';
import AquecMag from '../../../../img/analysis-macrovector-02-b 6.png';
import TubEns from '../../../../img/analysis-macrovector-02-b 4.png';
import CapsPor from '../../../../img/analysis-macrovector-02-b 1.png';
import Centrif from '../../../../img/analysis-macrovector-02-b 14.png';

/**
import Centrif from '../../../../img/analysis-macrovector-01 5.png';
import Centrif from '../../../../img/analysis-macrovector-01 12.png';
import Centrif from '../../../../img/analysis-macrovector-02-b 2.png';
import Centrif from '../../../../img/analysis-macrovector-02-b 3.png';
import Centrif from '../../../../img/analysis-macrovector-02-b 8.png';
import Centrif from '../../../../img/analysis-macrovector-02-b 9.png';
import Centrif from '../../../../img/analysis-macrovector-02-b 10.png';
import Centrif from '../../../../img/analysis-macrovector-02-b 11.png';
import Centrif from '../../../../img/analysis-macrovector-02-b 12.png';
import Centrif from '../../../../img/analysis-macrovector-02-b 13.png';
import Centrif from '../../../../img/analysis-macrovector-02-b 15.png';
import Centrif from '../../../../img/analysis-macrovector-03 1.png';
import Centrif from '../../../../img/analysis-macrovector-03 4.png';
*/

export default function ContentCenter() {
  return (
    <Container>
      <Title>Todas as categorias</Title>
      <Body>
        <CentralListOfCategories01 title="Medição e análise">
          <CentralListOfCategories02 title="Preparação de amostras">
            <CentralCard01
              title="Vidrarias variadas"
              imgSrc={Vidraria}
              imgAlt="Vidraria"
              route="/"
            />
            <CentralCard01
              title="Decantadores"
              imgSrc={Decant}
              imgAlt="Decantadores"
              route="/"
            />
            <CentralCard01
              title="Provetas"
              imgSrc={Proveta}
              imgAlt="Provetas"
              route="/"
            />
            <CentralCard01
              title="Tubos de ensaio"
              imgSrc={TubEns}
              imgAlt="Tubos de ensaio"
              route="/"
            />
            <CentralCard01
              title="Cápsula de porcelana"
              imgSrc={CapsPor}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Centrífugas"
              imgSrc={CentrifTub}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Bancada de ensaios"
              imgSrc={BancEns}
              imgAlt="Microscope"
              route="/"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Análises físico-químicas">
            <CentralCard01
              title="Balanças"
              imgSrc={Balanca1}
              imgAlt="Balanças"
              route="/"
            />
            <CentralCard01
              title="Espectrômetro de massa"
              imgSrc={Espect1}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Analisador FTIR e NDIR"
              imgSrc={Analis1}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Evaporador"
              imgSrc={Evap}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Bancada de análise de composição"
              imgSrc={Analis2}
              imgAlt="Microscope"
              route="/"
            />

            <CentralCard01
              title="Espectro- fotômetro"
              imgSrc={Espect2}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Aquecedor elétrico"
              imgSrc={AquecMag}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Aquecedor magnético"
              imgSrc={Centrif}
              imgAlt="Microscope"
              route="/"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Instrumentos óticos">
            <CentralCard01
              title="Microscópios"
              imgSrc={microscope}
              imgAlt="Microscope"
              route="/page-01"
            />
            <CentralCard01
              title="Câmeras termográficas"
              imgSrc={CamTerm}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Câmeras de alta velocidade"
              imgSrc={CamVelo}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Câmeras DSLR"
              imgSrc={CamDsrl}
              imgAlt="Microscope"
              route="/"
            />
          </CentralListOfCategories02>
        </CentralListOfCategories01>
        <CentralListOfCategories01 title="Fabricação e prototipagem">
          <CentralListOfCategories02 title="Fabricação pesada manual">
            <CentralCard01
              title="Torno Convencional"
              imgSrc={TornoConv2}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Torno vertical"
              imgSrc={TornoVert}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Furadeira de bancada"
              imgSrc={Furadeira}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Fresadoras"
              imgSrc={FresaConv1}
              imgAlt="Microscope"
              route="/"
            />

            <CentralCard01
              title="Mandriladoras"
              imgSrc={Mandriladora}
              imgAlt="Mandriladora"
              route="/"
            />

            <CentralCard01
              title="Retificadoras"
              imgSrc={RetifPLan}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Rebolos"
              imgSrc={RetifBanc}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Prensa hidráulica"
              imgSrc={PrensaHidraulica}
              imgAlt="Prensa hiráulica"
              route="/"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Fabricação automatizada">
            <CentralCard01
              title="Corte a Laser"
              imgSrc={CorteLaser}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Tornos CNC"
              imgSrc={TornoCNC1}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Fresadora CNC"
              imgSrc={FresaConv2}
              imgAlt="Fresadora CNC"
              route="/"
            />
            <CentralCard01
              title="Injetoras"
              imgSrc={Injetora}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Vacuum Forming"
              imgSrc={VacuumForming}
              imgAlt="Vacuum Forming"
              route="/"
            />
            <CentralCard01
              title="Impressoras 3D"
              imgSrc={Impressora3D}
              imgAlt="Impressora 3D"
              route="/"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Tratamentos térmicos">
            <CentralCard01
              title="Forno de indução"
              imgSrc={FornoInducao}
              imgAlt="Forno de indução"
              route="/"
            />
            <CentralCard01
              title="Muflas"
              imgSrc={Mufla}
              imgAlt="Mufla"
              route="/"
            />
            <CentralCard01
              title="Termo reatores"
              imgSrc={TermoReator}
              imgAlt="Termo reatores"
              route="/"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Tratamentos eletromagnéticos">
            <CentralCard01
              title="Reator de Plasma"
              imgSrc={Reat1}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Reator de plasma"
              imgSrc={Reat2}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Microreator de plasma"
              imgSrc={Reat3}
              imgAlt="Microscope"
              route="/"
            />
          </CentralListOfCategories02>

          <CentralListOfCategories02 title="Sistemas de movimentação">
            <CentralCard01
              title="Esteira"
              imgSrc={Esteira}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Braço robótico industrial 3DOF"
              imgSrc={Brac1}
              imgAlt="Microscope"
              route="/"
            />
            <CentralCard01
              title="Braço robótico industrial 6DOF"
              imgSrc={Brac2}
              imgAlt="Microscope"
              route="/"
            />
          </CentralListOfCategories02>
        </CentralListOfCategories01>
      </Body>
    </Container>
  );
}
