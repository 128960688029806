import React from 'react';

import { Body, Title } from './styles';

import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import CardModel2 from './CardModel2';

import Maq2 from '../../img/ensaio20000.jpg';
import Mini from '../../img/mini.png';
import Tribo from '../../img/tribometro.jpg';
import MicroDurom from '../../img/microdurometro.jpg';
import MEV from '../../img/MEV.jpg';
import Lumisizer from '../../img/Lumisizer.png';
import Espect from '../../img/Espectrofotometro.png';

export default function Equipment() {
  return (
    <Body>
      <Title />
      <CentralListOfCategories01 title="Análise">
        <CentralListOfCategories02 title="Ensaios mecânicos">
          <CardModel2
            imgSrc={Maq2}
            title="Máquina de ensaio universal EMIC 200"
            description="Carga máxima = 200kN"
            name="Operador: Antônio de Souza"
          />
          <CardModel2
            imgSrc={Mini}
            title="Mini dispositivos de flexão"
            description="Estudo de nucleação e propagação de trincas em metais dúcteis e compósitos"
            name="Operador: Antônio de Souza"
          />
          <CardModel2
            imgSrc={Tribo}
            title="Tribômetro"
            description="Desenvolvido no Depto. Eng. Mecânica da UFSC (LMP e CERMAT, 2010-2013)"
            name="Operador: Antônio de Souza"
          />
          <CardModel2
            imgSrc={MicroDurom}
            title="Microdurômetro"
            description="Carga máxima = 2kg"
            name="Operador: Antônio de Souza"
          />
        </CentralListOfCategories02>
        <CentralListOfCategories02 title="Ensaios ópticos">
          <CardModel2
            imgSrc={MEV}
            title="Microscópio eletrônico de varredura"
            description="MEV HITACHI TM3030"
            name="Operador: Antônio de Souza"
          />
          <CardModel2
            imgSrc={Lumisizer}
            title="Lumisizer"
            description="Distribuição de tamanho de partícula na faixa: 20nm-100µm."
            name="Operador: Antônio de Souza"
          />
          <CardModel2
            imgSrc={Espect}
            title="Espectrofotômetro"
            description="Faixa espectral de medida: 360 to 740 nm"
            name="Operador: Antônio de Souza"
          />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
    </Body>
  );
}
