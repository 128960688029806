import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Container, Title, Image, ContainerTop } from './styles';
import 'react-tabs/style/react-tabs.css';

import Team from './team';
import Services from './services';
import Equipment from './Equipment';

import LmpIcon from '../../img/Group 145.png';

export default function ContentCenter() {
  return (
    <Container>
      <Title>Laboratório de Mecânica de Precisão</Title>
      <Tabs>
        <ContainerTop>
          <Image src={LmpIcon} alt="" />
          <TabList id="List">
            <Tab>Serviços</Tab>
            <Tab>Equipamentos</Tab>
            <Tab>Equipe</Tab>
          </TabList>
        </ContainerTop>
        <TabPanel>
          <Services />
        </TabPanel>

        <TabPanel>
          <Equipment />
        </TabPanel>
        <TabPanel>
          <Team />
        </TabPanel>
      </Tabs>
    </Container>
  );
}

/*
 */
