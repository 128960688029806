import React from 'react';
import { Body, Title } from './styles';
import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import CentralCard04 from '../../../../components/CentralCard04';

// import { Container } from './styles';

import Usinagem from '../../img/Usinag.png';
import LaserWelding from '../../img/LaserWelding.png';
import Remelting from '../../img/Remelting.png';
import AditManuf from '../../img/AditManuf.png';
import Prep from '../../img/prep.png';
import Anali100x from '../../img/anali100x.png';
import Anali1000x from '../../img/anali1000x.png';
import Desgaste from '../../img/Desgaste.png';
import Esforcos from '../../img/Esforços.png';

function Services() {
  return (
    <Body>
      <Title />

      <CentralListOfCategories01 title="Análise">
        <CentralListOfCategories02 title="">
          <CentralCard04
            title="Preparação de amostras"
            imgSrc={Prep}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Análise de microscopia com ampliação de até 100x"
            imgSrc={Anali100x}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Análise de microscopia com amplicação de até 1000x"
            imgSrc={Anali1000x}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Avaliação do desgaste de ferramentas"
            imgSrc={Desgaste}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Medição de esforços de usinagem"
            imgSrc={Esforcos}
            imgAlt=""
            route="/template-page-03"
          />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
      <CentralListOfCategories01 title="Fabricação">
        <CentralListOfCategories02 title="">
          <CentralCard04
            title="Usinagem de componentes"
            imgSrc={Usinagem}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Soldagem de componentes a laser"
            imgSrc={LaserWelding}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Refusão de superfícies a laser"
            imgSrc={Remelting}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Manufatura aditiva de metais"
            imgSrc={AditManuf}
            imgAlt=""
            route="/template-page-03"
          />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
    </Body>
  );
}

export default Services;
