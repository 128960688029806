import React from 'react';
import { Container, Title, Body } from './styles';

import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import CentralCard04 from '../../../../components/CentralCard04';

import Equipe from '../../img/15167 1.png';
import Demandas from '../../img/image 4.png';
import Recursos from '../../img/nuts-bolts-washers-set 2.png';
import Add from '../../img/Frame 6.png';
import Centro from '../../img/centro.png';
import Digital from '../../img/digital.png';
import Eletro from '../../img/eletro.png';
import Embutidora from '../../img/embutidora.png';
import Embutidora2 from '../../img/embutidora2.png';
import Escalimetro from '../../img/escalimetro.png';
import Micrometro from '../../img/micrometro.png';
import Stereoscopio from '../../img/microscopio.png';
import Retificadora from '../../img/retificadora.png';
import Torno1 from '../../img/torno1.png';
import Torno2 from '../../img/torno2.png';
import Torno3 from '../../img/torno1.png';
import Torno4 from '../../img/torno4.png';

export default function ContentCenter() {
  return (
    <Container>
      <Title>Meu painel</Title>
      <Body>
        <CentralListOfCategories01 title="Menu principal">
          <CentralListOfCategories02 title="Resumo">
            <CentralCard04
              title="Equipe"
              imgSrc={Equipe}
              imgAlt=""
              route="/equipe"
            />
            <CentralCard04
              title="Demandas"
              imgSrc={Demandas}
              imgAlt=""
              route="/demandas"
            />
            <CentralCard04
              title="Recursos"
              imgSrc={Recursos}
              imgAlt=""
              route="/recursos"
            />
            <CentralCard04
              title="Adicionar novo tópico"
              imgSrc={Add}
              imgAlt=""
              route="/dashboard"
            />
          </CentralListOfCategories02>
        </CentralListOfCategories01>
        <CentralListOfCategories01 title="Recursos">
          <CentralListOfCategories02 title="Instrumentos óticos">
            <CentralCard04
              title="Stereoscópio AmScope 7x-45x"
              imgSrc={Stereoscopio}
              imgAlt="Stereoscópio AmScope 7x-45x"
              route="/"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Medição com contato">
            <CentralCard04
              title="Paquímetro analógico Mitutoyo 0-150mm 0.05mm"
              imgSrc={Escalimetro}
              imgAlt="Paquímetro analógico Mitutoyo 0-150mm 0.05mm"
              route="/"
            />
            <CentralCard04
              title="Paquímetro digital Mitutoyo 0-200mm 0.02mm"
              imgSrc={Digital}
              imgAlt="Paquímetro digital Mitutoyo 0-200mm 0.02mm"
              route="/"
            />
            <CentralCard04
              title="Micrômetro externo analógico Mitutoyo 0-25mm"
              imgSrc={Micrometro}
              imgAlt="Micrômetro externo analógico Mitutoyo 0-25mm"
              route="/"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Preparação de amostras">
            <CentralCard04
              title="Embutidora EM30D"
              imgSrc={Embutidora}
              imgAlt="Embutidora EM30D"
              route="/"
            />
            <CentralCard04
              title="Embutidora Arotech PRE 30Mi"
              imgSrc={Embutidora2}
              imgAlt="Embutidora Arotech PRE 30Mi"
              route="/"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Fabricação manual">
            <CentralCard04
              title="Torno universal ROMI I-45"
              imgSrc={Torno1}
              imgAlt="Torno universal ROMI I-45"
              route="/"
            />
            <CentralCard04
              title="Torno universal ROMI Tormax 20"
              imgSrc={Torno2}
              imgAlt="Torno universal ROMI Tormax 20"
              route="/"
            />
            <CentralCard04
              title="Torno universal ROMI S-20A"
              imgSrc={Torno3}
              imgAlt="Torno universal ROMI S-20A"
              route="/"
            />
            <CentralCard04
              title="Máquina de eletroerosão Engemaq"
              imgSrc={Eletro}
              imgAlt="Máquina de eletroerosão Engemaq"
              route="/"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Fabricação CNC">
            <CentralCard04
              title="Retificadora CNC Zema Pratika Flexa-600-L"
              imgSrc={Retificadora}
              imgAlt="Retificadora CNC Zema Pratika Flexa-600-L"
              route="/"
            />
            <CentralCard04
              title="Centro de usinagem Charles MVC-955"
              imgSrc={Centro}
              imgAlt="Centro de usinagem Charles MVC-955"
              route="/"
            />
            <CentralCard04
              title="Torno CNC Heyligenstaedt Heynumat 10"
              imgSrc={Torno4}
              imgAlt="Torno CNC Heyligenstaedt Heynumat 10"
              route="/"
            />
          </CentralListOfCategories02>
        </CentralListOfCategories01>
      </Body>
    </Container>
  );
}
