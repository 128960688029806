import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;

  @media (min-width: 768px) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const Title = styled.div`
  padding: 0.5rem 0.5rem;
  color: #495057;
  font-size: 1rem;
  font-weight: 500;
  border-bottom: 2px solid #ddff00;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  #card {
    display: inline-table;
    margin: 20px 15px 0px 10px;
  }
  #card1 {
    margin: 20px 15px 0px 10px;
    width: 200px;
  }
  #image {
    height: 130px;
  }
  #card2 {
    padding: 16px 0px 0px 16px;
    margin: 20px 15px 0px 10px;
    height: 50px;
  }
  #card3 {
    flex-direction: column;
    align-items: inherit;
    height: 100%;
    outline: 0px;
  }
  #button {
    outline: 0px;
  }
`;

export const BodyTeam = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  img {
    filter: none;
  }
  #card1 {
    margin: 20px 15px 0px 10px;
    width: 150px;
  }
  #image {
    height: 130px;
  }
  #card3 {
    flex-direction: column;
    align-items: inherit;
    height: 100%;
    outline: 0px;
  }
`;
export const Image = styled.img`
  width: 120px;
  height: 120px;
  background: #ffffff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const ContainerTop = styled.div`
  #List {
    width: 100%;
    padding-left: 152px;
    margin-bottom: 40px;
  }
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  padding-top: 18px;
  padding-bottom: 14px;
  align-items: center;
`;
