import React from 'react';
import { Body, Title } from './styles';
import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import CentralCard04 from '../../../../components/CentralCard04';
import Sold1 from '../../img/image95.png';
import Sold2 from '../../img/image84.png';
import Sold3 from '../../img/image106.png';
import Anali1 from '../../img/image117.png';
import Anali2 from '../../img/image51.png';
import Anali3 from '../../img/image73.png';
import Anali4 from '../../img/image128.png';
import Auto1 from '../../img/image18.png';
import Auto2 from '../../img/automacao_soldagem.png';

// import { Container } from './styles';

function Services() {
  return (
    <Body>
      <Title />
      <CentralListOfCategories01 title="Análise">
        <CentralListOfCategories02 title="Ensaios não destrutivos">
          <CentralCard04
            title="Análises por filmagem com câmera termográfica"
            imgSrc={Anali1}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Análises por filmagem com câmera de alta velocidade "
            imgSrc={Anali2}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Inspeção de soldas e ensaios não destrutivos"
            imgSrc={Anali3}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Produção e análise de imagens, vídeos e dados com videoscópio 3D-Stereo"
            imgSrc={Anali4}
            imgAlt=""
            route="/template-page-03"
          />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
      <CentralListOfCategories01 title="Fabricação">
        <CentralListOfCategories02 title="Aplicações especiais">
          <CentralCard04
            title="Aplicação de sistemas para soldagem orbital"
            imgSrc={Sold1}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Desenvolvimento de procedimentos de soldagem"
            imgSrc={Sold2}
            imgAlt=""
            route="/template-page-03"
          />

          <CentralCard04
            title="Aplicação de sistemas para soldagem orbital de Câmara Fechada"
            imgSrc={Sold3}
            imgAlt=""
            route="/template-page-03"
          />
        </CentralListOfCategories02>
      </CentralListOfCategories01>

      <CentralListOfCategories01 title="Consultoria e projeto">
        <CentralListOfCategories02 title="">
          <CentralCard04
            title="Assessoria para integração e programação de sistemas robotizados"
            imgSrc={Auto1}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Projetos de desenvolvimento de soluções tecnológicas para automação da soldagem"
            imgSrc={Auto2}
            imgAlt=""
            route="/template-page-03"
          />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
    </Body>
  );
}

export default Services;
