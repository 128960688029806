import React from 'react';
import { Container } from './styles';

import SideBarList from '../../../../components/SideBarList';
import SideBarCard02 from '../../../../components/SideBarCard02';

import LCME from '../../img/lcme.PNG';
import Labsolda from '../../img/labsolda.png';
import LabMat from '../../img/labmat.png';
import LMP from '../../img/lmp copy.png';

export default function ContentLeft() {
  return (
    <Container>
      <SideBarList title="Laboratórios relacionados">
        <SideBarCard02
          imgPath={LMP}
          title="LMP"
          subtitle="Laboratório de Mecânica de Precisão"
          route="/lmp"
        />
        <SideBarCard02
          imgPath={LabMat}
          title="LabMat"
          subtitle="Laboratório de Materiais"
          route="/labmat"
        />
        <SideBarCard02
          imgPath={Labsolda}
          title="LABSOLDA"
          subtitle="Instituto de Soldagem e Mecatrônica"
          route="/labsolda"
        />
        <SideBarCard02
          imgPath={LCME}
          title="LCME"
          subtitle="Laboratório Central de Microscopia Eletrônica"
          route="/lcme"
        />
      </SideBarList>
    </Container>
  );
}
