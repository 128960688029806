import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    width: 190,
    height: 155,
    textAlign: 'center',
    marginTop: 15,
    marginRight: 15,
  },
  avatar: {
    width: 60,
    height: 60,
    margin: 'auto',
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 8,
    marginBottom: 0,
  },
  subheader: {
    fontSize: 14,
    color: palette.grey[500],
    marginBottom: '0.875em',
  },
}));

export const TeamCard = React.memo(function ProfileCard(props) {
  const styles = useStyles();
  return (
    <Card className={cx(styles.card)}>
      <CardContent>
        <Avatar className={styles.avatar} src={props.ImgSrc} />
        <h3 className={styles.heading}>{props.name}</h3>
        <span className={styles.subheader}>{props.description}</span>
      </CardContent>
    </Card>
  );
});

export default TeamCard;
