import React from 'react';

import { PopupContainer, PopupInner, Success } from './styles';
import Tick from './img/tick.png';

export default function Budget() {
  return (
    <PopupContainer>
      <PopupInner>
        <Success>
          <h1>Orçamento enviado com sucesso!</h1>
          <img src={Tick} alt="" />
        </Success>
      </PopupInner>
    </PopupContainer>
  );
}
