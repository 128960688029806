import React from 'react';
import { Body, Title } from './styles';
import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import ServiceCard from '../../../../components/ServiceCard';

import SintLub from '../../img/SintLub.png';
import Porous from '../../img/porous.png';
import ThinFilm from '../../img/thinFilm.png';
import Nitriding from '../../img/nitriding.png';
import Plasma from '../../img/plasma.png';
import Surface from '../../img/surface.png';
import Tribosint from '../../img/tribosint.png';
import Composite from '../../img/composite.png';

function Services() {
  return (
    <Body>
      <Title />

      <CentralListOfCategories01 title="Análise">
        <CentralListOfCategories02 title="">
          <ServiceCard imgSrc={Surface} title="Caracterização superficial" />
          <ServiceCard
            imgSrc={Tribosint}
            title="Comportamento tribológico de materiais"
          />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
      <CentralListOfCategories01 title="Fabricação">
        <CentralListOfCategories02 title="Metalurgia do pó">
          <ServiceCard
            imgSrc={SintLub}
            title="Materiais sinterizados autolubrificantes a seco"
          />
          <ServiceCard imgSrc={Porous} title="Materiais sinterizados porosos" />
          <ServiceCard
            imgSrc={Composite}
            title="Desenvolvimento de ligas e compósitos"
          />
        </CentralListOfCategories02>
        <CentralListOfCategories02 title="Processamentos a plasma">
          <ServiceCard
            imgSrc={ThinFilm}
            title="Filmes finos: DLC (diamond like carbono)"
          />
          <ServiceCard imgSrc={Nitriding} title="Tratamentos termoquímicos" />
          <ServiceCard imgSrc={Plasma} title="Sinterização por plasma" />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
    </Body>
  );
}

export default Services;
