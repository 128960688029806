import React from 'react';
import NavItem from './NavItem';
import Find from './Find';
import {
  Container,
  ActionContainer,
  Brand,
  MenuToggleButton,
  MenuContainer,
} from './styles';
import bellWithNotification from './NavItem/img/bell-with-notification.png';
import more from './NavItem/img/more.png';
import polygon from './NavItem/img/polygon.png';
import avatar from './NavItem/img/avatar.jpg';

export class FlexNavbar extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen() {
    this.setState({ open: !this.state.open });
  }

  render() {
    return (
      <Container>
        <ActionContainer>
          <Brand to="/">peerLab</Brand>
          <MenuToggleButton onClick={this.toggleOpen} />
        </ActionContainer>
        <MenuContainer open={this.state.open}>
          <Find />
          <ul>
            <NavItem item="Contato" route="#" />
            <NavItem item="Pedidos" route="#" />
            <NavItem item="Login" route="/signin" />
          </ul>
        </MenuContainer>
      </Container>
    );
  }
}

export class AuthFlexNavbar extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen() {
    this.setState({ open: !this.state.open });
  }

  render() {
    return (
      <Container>
        <ActionContainer>
          <Brand to="/">peerLab</Brand>
          <MenuToggleButton onClick={this.toggleOpen} />
        </ActionContainer>
        <MenuContainer open={this.state.open}>
          <Find />
          <ul>
            <li>
              <a href="/">
                <img src={bellWithNotification} alt="" />
              </a>
            </li>
            <li>
              <a href="/">
                <div className="img">
                  <img src={more} alt="" id="more" />
                  <img src={polygon} alt="" className="poly" id="poly1" />
                </div>
              </a>
            </li>
            <li>
              <a href="/">
                <div className="img" id="last">
                  <img src={avatar} alt="" id="avatar" />
                  <img src={polygon} alt="" className="poly" />
                </div>
              </a>
            </li>
          </ul>
        </MenuContainer>
      </Container>
    );
  }
}
