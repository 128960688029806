import React from 'react';
import { Container, Title, Body } from './styles';

import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import CentralCard04 from '../../../../components/CentralCard04';

import TEM1 from '../../img/image62.png';
import TEM2 from '../../img/image73.png';
import MEV1 from '../../img/image51.png';
import MEV2 from '../../img/image40.png';
import CONF from '../../img/image29.png';
import FLUOR from '../../img/image18.png';

export default function ContentCenter() {
  return (
    <Container>
      <Title />
      <Body>
        <CentralListOfCategories01 title="Equipamentos">
          <CentralListOfCategories02 title="Instrumentos óticos">
            <CentralCard04
              title="Microscópio eletrônico de transmissão JEM-1011 TEM(TEM 100 kV)"
              imgSrc={TEM1}
              imgAlt="JEM-1011 TEM (TEM 100 kV)"
              route="/"
            />
            <CentralCard04
              title="Microscópio eletrônico de transmissão JEM-2100 TEM(TEM 200kV)"
              imgSrc={TEM2}
              imgAlt="JEM-2100 TEM (TEM 200kV)"
              route="/"
            />
            <CentralCard04
              title="Microscópio eletrônico de varredura JEOL JSM-6390LV"
              imgSrc={MEV1}
              imgAlt="JEOL JSM-6390LV Scanning Electron Microscope (MEV)"
              route="/"
            />
            <CentralCard04
              title="Microscópio eletrônico de varredura JEOL JSM-6701F(FEG)"
              imgSrc={MEV2}
              imgAlt="JEOL JSM-6701F Scanning Electron Microscope (FEG)"
              route="/"
            />
            <CentralCard04
              title="Microscópio confocal Leica DMI6000 B"
              imgSrc={CONF}
              imgAlt="Leica DMI6000 B Microscope (Confocal)"
              route="/"
            />
            <CentralCard04
              title="Microscópio de Fluorescência Leica DM5500 B"
              imgSrc={FLUOR}
              imgAlt="Leica DM5500 B (Microscópio de Fluorescência)"
              route="/"
            />
          </CentralListOfCategories02>
        </CentralListOfCategories01>
      </Body>
    </Container>
  );
}
