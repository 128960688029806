import React from 'react';

import { Form, Input } from '@rocketseat/unform';
import { Wrapper, Down } from './styles';
import Logo from './img/Logo.png';

export default function SignIn() {
  return (
    <Wrapper>
      <img src={Logo} alt="PeerLab" />
      <Form /* onSubmit={handleSubmit} */>
        <Input name="email" type="email" placeholder="Seu email" />
        <Input name="password" type="password" placeholder="Sua senha" />
        <div>
          <button
            type="submit"
            onClick={() => (window.location.href = '/dashboard')}
          >
            Entrar
          </button>
          <a href="/"> Esqueceu sua senha? </a>
        </div>

        <hr />
        <Down>
          <a href="/" id="right">
            Termos de uso e política de privacidade
          </a>
          <p>Não tem conta?</p>
          <a href="/" id="left">
            Criar conta
          </a>
        </Down>
      </Form>
    </Wrapper>
  );
}
