import React from 'react';
import { Container, Title, Body } from './styles';

import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import CentralCard04 from '../../../../components/CentralCard04';
import CentralCard05 from '../../../../components/CentralCard05';
import Popup from '../../../../components/Popup';

import Equipe from '../../img/15167 1.png';
import Demandas from '../../img/image 4.png';
import Recursos from '../../img/nuts-bolts-washers-set 2.png';
import Add from '../../img/Frame 6.png';
import LabSolda from '../../img/dest_labsolda 3.png';
import Avatar1 from '../../img/avataaars1.png';
import Avatar2 from '../../img/avataaars2.png';
import Centro from '../../img/centro.png';
import Dispositivo from '../../img/dispositivo.png';
import Lab from '../../img/lab.png';
import Microdureza from '../../img/microdureza.png';
import Microscopy from '../../img/microscopy.png';
import Microscopy2 from '../../img/microscopy2.png';
import Planta from '../../img/planta.png';
import Spectrum from '../../img/spectrum.png';
import Torno from '../../img/torno.png';
import User from '../../img/user.png';

export default class ContentCenter extends React.Component {
  constructor() {
    super();
    this.state = {
      showPopup: false,
    };
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  }

  render() {
    return (
      <Container>
        <Title>Meu painel</Title>
        <Body>
          <CentralListOfCategories01 title="Menu principal">
            <CentralListOfCategories02 title="Resumo">
              <CentralCard04
                title="Equipe"
                imgSrc={Equipe}
                imgAlt=""
                route="/equipe"
              />
              <CentralCard04
                title="Demandas"
                imgSrc={Demandas}
                imgAlt=""
                route="/demandas"
              />
              <CentralCard04
                title="Recursos"
                imgSrc={Recursos}
                imgAlt=""
                route="/recursos"
              />
              <CentralCard04
                title="Adicionar novo tópico"
                imgSrc={Add}
                imgAlt=""
                route="/dashboard"
              />
            </CentralListOfCategories02>
          </CentralListOfCategories01>
          <CentralListOfCategories01 title="Demandas">
            <CentralListOfCategories02 title="Demandas do mercado">
              <CentralCard05
                Click={this.togglePopup.bind(this)}
                imgSrc1={Microscopy}
                imgSrc2={Spectrum}
                imgSrc3={User}
                text1="Análise de composição química de amostra de elemento de fixação especial"
                text2="Criado por Paulo, Ontem"
              />
              <CentralCard05
                Click={this.togglePopup.bind(this)}
                imgSrc1={Torno}
                imgSrc2={Planta}
                imgSrc3={User}
                text1="Fabricação de eixo para protótipo de equipamento para agricultura familiar"
                text2="Criado por Sara, há 1h"
              />
            </CentralListOfCategories02>
            <CentralListOfCategories02 title="Demandas de outros laboratórios">
              <CentralCard05
                Click={this.togglePopup.bind(this)}
                imgSrc1={Microscopy2}
                imgSrc2={Microdureza}
                imgSrc3={Avatar1}
                imgSrc6={LabSolda}
                text1="Ensaio de microdureza vickers em amostra de componente soldado"
                text2="Criado por Larissa, há 2 dias"
              />
              <CentralCard05
                Click={this.togglePopup.bind(this)}
                imgSrc1={Centro}
                imgSrc2={Dispositivo}
                imgSrc3={Avatar2}
                imgSrc6={Lab}
                text1="Fabricação de dispositivo em aço inoxidável para fixação de microfone de medição"
                text2="Criado por Júlio, há 1 hora"
              />
            </CentralListOfCategories02>
          </CentralListOfCategories01>
        </Body>
        {this.state.showPopup ? (
          <Popup
            Title="Análise de composição química em amostra de elemento de fixação especial"
            closePopup={this.togglePopup.bind(this)}
          />
        ) : null}
      </Container>
    );
  }
}
