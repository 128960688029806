import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from './Route';
import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import Requests from '../pages/Requests';
import Team from '../pages/Team';
import Resources from '../pages/Resources';
import TemplatePageWithWireframe from '../pages/_templates/TemplatePageWithWireframe';
import TemplatePage00 from '../pages/_templates/TemplatePage00';
import TemplatePage01 from '../pages/_templates/TemplatePage01';
import TemplatePage02 from '../pages/_templates/TemplatePage02';
import TemplatePage03 from '../pages/_templates/TemplatePage03';
import Page00 from '../pages/Page00';
import Page01 from '../pages/Page01';
import Page02 from '../pages/Page02';
import Teste from '../pages/teste_popup';
import LmpPage from '../pages/LabPage-LMP';
import LabsoldaPage from '../pages/LabPage-LABSOLDA';
import LcmePage from '../pages/LabPage-LCME';
import LabmatPage from '../pages/LabPage-LABMAT';
import CermatPage from '../pages/LabPage-CERMAT';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Page00} />
      <Route path="/page-01" component={Page01} />
      <Route path="/page-02" component={Page02} />
      <Route path="/template-page-00" component={TemplatePage00} />
      <Route path="/template-page-01" component={TemplatePage01} />
      <Route path="/template-page-02" component={TemplatePage02} />
      <Route path="/template-page-03" component={TemplatePage03} />
      <Route path="/wireframe" component={TemplatePageWithWireframe} />
      <Route path="/teste" component={Teste} isPrivate />
      <Route path="/signin" component={SignIn} isSign />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/demandas" component={Requests} isPrivate />
      <Route path="/equipe" component={Team} isPrivate />
      <Route path="/recursos" component={Resources} isPrivate />
      <Route path="/lmp" component={LmpPage} isPrivate />
      <Route path="/labsolda" component={LabsoldaPage} isPrivate />
      <Route path="/lcme" component={LcmePage} isPrivate />
      <Route path="/labmat" component={LabmatPage} />
      <Route path="/cermat" component={CermatPage} isPrivate />
      <Redirect to="/" />
    </Switch>
  );
}
