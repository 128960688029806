import styled from 'styled-components';

export const PopupContainer = styled.div`
  position: fixed;
  width: 342px;
  height: 384px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 200;
`;
export const PopupInner = styled.div`
  position: absolute;
  left: 330px;
  top: 100px;
  bottom: auto;
  width: 100%;
  z-index: 300;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const Top = styled.div`
  display: block;
  justify-content: center;
  margin: 14px 0px 9px 0px;
  a {
    float: right;
    margin-top: -31px;
    margin-right: 15px;
    img {
      width: 10px;
      height: 10px;
    }
  }
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #777777;
    margin: 0 0 0 135px;
  }
`;

export const Margin = styled.hr`
  width: 315px;
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Main = styled.div`
  margin: 0;
  min-height: 24px;
  position: relative;
  width: 300px;
  float: left;

  #div {
    display: flex;
    flex-direction: row;
    margin-left: 51px !important;
    margin-top: 5px;
  }
  #icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
  }
  #options {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #777777;
  }
`;

export const Requester = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  padding-top: 14px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  div {
    width: 110%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #777777;
  }
  a {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-decoration-line: underline;
    color: #777777;
    margin-left: 46px;
  }

  #input1 {
    width: 269px;
  }
  #input2 {
    width: 117px;
  }
  #input3 {
    width: 141px;
  }
  #input4 {
    width: 117px;
  }
`;

export const Input = styled.input`

    background: #F8F9FA;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 3px;
    margin: 0px 0px 9px 11px;
    height: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    padding: 9px 0px 9px 8px !important;

    &:focus {
      outline: none;
      &::placeholder {
        color: transparent;
      }
    }

    &::placeholder {
      color: #aaaaaa;
    }
  }
`;
export const DescriptionBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 342px;
`;

export const Button = styled.button`
  margin: 21px 0px 22px 0px;
  height: 32px;
  width: 315px;
  background: #444444;
  border-radius: 3px;
  border: 0;

  p {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
    color: #ffffff;
  }

  &:hover,
  &:focus {
    color: #495057;
    background-color: #dae0e5 !important;
    border: none;
    outline: none;
  }

  &:active {
    background-color: #9ae0e5 !important;
  }
`;
