import React from 'react';
import { Container, Body, Buttons, Wrapper, Img } from './styles';
import textIcon from './img/textIcon.png';

export default function CentralCard02(props) {
  return (
    <Container>
      <Img src={props.imgSrc} alt="" />
      <Body>
        <div>
          <h5>{props.title}</h5>
          <p>{props.text}</p>
        </div>
        <Wrapper>
          <img src={props.stars} alt="" />
          <Buttons>
            <a href={props.route}>
              <img src={textIcon} alt="" />
            </a>
            <button type="button" onClick={props.Click}>
              {props.buttonText} &raquo;
            </button>
          </Buttons>
        </Wrapper>
      </Body>
    </Container>
  );
}
