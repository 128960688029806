import React from 'react';
import { Container, Title, Body } from './styles';

import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import CentralCard04 from '../../../../components/CentralCard04';

import Equipe from '../../img/15167 1.png';
import Demandas from '../../img/image 4.png';
import Recursos from '../../img/nuts-bolts-washers-set 2.png';
import Add from '../../img/Frame 6.png';
import Avatar1 from '../../img/avataaars 1.png';
import Avatar2 from '../../img/avataaars (2) 1.png';
import Avatar3 from '../../img/avataaars (3) 1.png';
import Avatar4 from '../../img/avataaars (1) 1.png';
import Avatar5 from '../../img/avataaars (4) 1.png';
import Avatar6 from '../../img/avataaars (6) 1.png';
import Avatar7 from '../../img/avataaars (5) 1.png';
import Avatar8 from '../../img/avataaars (7) 1.png';
import Avatar9 from '../../img/avataaars (11) 1.png';
import Avatar10 from '../../img/avataaars (9) 1.png';
import Avatar11 from '../../img/avataaars (10) 1.png';
import Avatar12 from '../../img/avataaars (8) 1.png';
import Avatar13 from '../../img/avataaars (12) 1.png';
import Avatar14 from '../../img/avataaars (14) 1.png';
import Avatar15 from '../../img/avataaars (13) 1.png';

export default function ContentCenter() {
  return (
    <Container>
      <Title>Meu painel</Title>
      <Body>
        <CentralListOfCategories01 title="Menu principal">
          <CentralListOfCategories02 title="Resumo">
            <CentralCard04
              title="Equipe"
              imgSrc={Equipe}
              imgAlt="Microscope"
              route="/equipe"
            />
            <CentralCard04
              title="Demandas"
              imgSrc={Demandas}
              imgAlt="Microscope"
              route="/demandas"
            />
            <CentralCard04
              title="Recursos"
              imgSrc={Recursos}
              imgAlt="Microscope"
              route="/recursos"
            />
            <CentralCard04
              title="Adicionar novo tópico"
              imgSrc={Add}
              imgAlt="Microscope"
              route="/dashboard"
            />
          </CentralListOfCategories02>
        </CentralListOfCategories01>
        <CentralListOfCategories01 title="Equipe Atual">
          <CentralListOfCategories02 title="Professores">
            <CentralCard04
              title="Antônio de Souza"
              imgSrc={Avatar1}
              imgAlt="Microscope"
              route="/template-page-03"
            />
            <CentralCard04
              title="Júlia Andrade"
              imgSrc={Avatar2}
              imgAlt="Microscope"
              route="/template-page-03"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Pós-doutorandos">
            <CentralCard04
              title="Andressa Mello"
              imgSrc={Avatar3}
              imgAlt="Microscope"
              route="/template-page-03"
            />
            <CentralCard04
              title="Danilo Gondin"
              imgSrc={Avatar4}
              imgAlt="Microscope"
              route="/template-page-03"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Doutorandos">
            <CentralCard04
              title="Larissa Pereira"
              imgSrc={Avatar5}
              imgAlt="Microscope"
              route="/template-page-03"
            />
            <CentralCard04
              title="Bruna Bastos"
              imgSrc={Avatar6}
              imgAlt="Microscope"
              route="/template-page-03"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Mestrandos">
            <CentralCard04
              title="Júlio Amorim"
              imgSrc={Avatar7}
              imgAlt="Microscope"
              route="/template-page-03"
            />
            <CentralCard04
              title="Raissa Nakamura"
              imgSrc={Avatar8}
              imgAlt="Microscope"
              route="/template-page-03"
            />
            <CentralCard04
              title="Nelson Nagem"
              imgSrc={Avatar9}
              imgAlt="Microscope"
              route="/template-page-03"
            />
            <CentralCard04
              title="Paula Oliveira"
              imgSrc={Avatar10}
              imgAlt="Microscope"
              route="/template-page-03"
            />
          </CentralListOfCategories02>
          <CentralListOfCategories02 title="Graduandos">
            <CentralCard04
              title="Lucas Nogueira"
              imgSrc={Avatar11}
              imgAlt="Microscope"
              route="/template-page-03"
            />
            <CentralCard04
              title="Nathan Nunes"
              imgSrc={Avatar12}
              imgAlt="Microscope"
              route="/template-page-03"
            />
            <CentralCard04
              title="Luana Lacerda"
              imgSrc={Avatar13}
              imgAlt="Microscope"
              route="/template-page-03"
            />
          </CentralListOfCategories02>
        </CentralListOfCategories01>
        <CentralListOfCategories01 title="Alumni">
          <CentralListOfCategories02 title="2019">
            <CentralCard04
              title="Felipe Pedroso"
              imgSrc={Avatar14}
              imgAlt="Microscope"
              route="/template-page-03"
            />
            <CentralCard04
              title="Alessandra Franco"
              imgSrc={Avatar15}
              imgAlt="Microscope"
              route="/template-page-03"
            />
            <CentralCard04
              title="Ver todos"
              imgSrc={Add}
              imgAlt="Microscope"
              route="/template-page-03"
            />
          </CentralListOfCategories02>
        </CentralListOfCategories01>
      </Body>
    </Container>
  );
}
