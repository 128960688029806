import React from 'react';

import { Container, InfoContainer, InfoTitle, InfoCard } from './styles';

export default function SideBarCard03(props) {
  return (
    <>
      <Container>
        <InfoCard href={props.route1}>
          <InfoContainer>
            <img src={props.imgPath1} alt="" />
            <InfoTitle>{props.title1}</InfoTitle>
          </InfoContainer>
        </InfoCard>
        <InfoCard href={props.route2}>
          <InfoContainer>
            <img src={props.imgPath2} alt="" />
            <InfoTitle>{props.title2}</InfoTitle>
          </InfoContainer>
        </InfoCard>
      </Container>
    </>
  );
}
