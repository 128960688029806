import React from 'react';
import { Body, Title } from './styles';

import CentralListOfCategories01 from '../../../../components/CentralListOfCategories01';
import CentralListOfCategories02 from '../../../../components/CentralListOfCategories02';
import CentralCard04 from '../../../../components/CentralCard04';

import Sold1 from '../../img/image150.png';
import Sold2 from '../../img/image194.png';
import Sold3 from '../../img/image227.png';
import Anali1 from '../../img/image205.png';
import Anali2 from '../../img/image172.png';
import Anali3 from '../../img/image183.png';
import Anali4 from '../../img/image51.png';
import Auto1 from '../../img/image216.png';
import Auto2 from '../../img/image139.png';
import Auto3 from '../../img/image161.png';

// import { Container } from './styles';

function Equipment() {
  return (
    <Body>
      <Title />
      <CentralListOfCategories01 title="Análise">
        <CentralListOfCategories02 title="Inspeção de soldagem">
          <CentralCard04
            title="Misturador de gases Ar e C02 da empresa WITT"
            imgSrc={Anali1}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Analisador de misturas gasosas de bancada Thermco"
            imgSrc={Anali2}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="2 sistemas portáteis de monitoração e tratamento de dados de soldagem SAP"
            imgSrc={Anali3}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Sistema de filmagem em alta velocidade, incluindo câmera e sistema de aquisição de dados"
            imgSrc={Anali4}
            imgAlt=""
            route="/template-page-03"
          />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
      <CentralListOfCategories01 title="Fabricação">
        <CentralListOfCategories02 title="Soldagem">
          <CentralCard04
            title="Tochas de soldagem MIG/MAG. TIG e Plasma"
            imgSrc={Sold1}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Tochas manuais e tochas automáticas para soldagem PTA-P"
            imgSrc={Sold2}
            imgAlt=""
            route="/template-page-03"
          />

          <CentralCard04
            title="Equipamento para solda a ponto por resistência "
            imgSrc={Sold3}
            imgAlt=""
            route="/template-page-03"
          />
        </CentralListOfCategories02>
        <CentralListOfCategories02 title="Automação">
          <CentralCard04
            title="Robô MOTOMAN UP6 de 6 eixos, mesa rotativa independente, fonte de soldagem micro-processada"
            imgSrc={Auto1}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Robô MOTOMAN HP20D de 6 eixos, mesa rotativa integrada, fonte de soldagem CMT 3200 integrada"
            imgSrc={Auto2}
            imgAlt=""
            route="/template-page-03"
          />
          <CentralCard04
            title="Robô MOTOMAN SIA10D de 7 eixos, mesa rotativa integrada, fonte de soldagem STT 455M integrada"
            imgSrc={Auto3}
            imgAlt=""
            route="/template-page-03"
          />
        </CentralListOfCategories02>
      </CentralListOfCategories01>
    </Body>
  );
}

export default Equipment;
