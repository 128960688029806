import React from 'react';
import { Container } from './styles';

import SideBarList from '../../../../components/SideBarList';
import SideBarCard03 from '../../../../components/SideBarCard03';
import Baquelite from '../../img/baquelite.jpeg';
import Luvas from '../../img/luvas-borracha.png';
import PanoPoli from '../../img/pano-polimento.jpg';
import Lixa from '../../img/lixa-dagua.jpg';
import Discos from '../../img/disco-corte.jpg';
import PastaDiam from '../../img/pasta-de-diamante.jpg';
import CilindroNitrogenio from '../../img/cilindro-nitrogenio.jpg';
import FilamentoTungstenio from '../../img/filamento-tungstenio.PNG';

export default function ContentLeft() {
  return (
    <Container>
      <SideBarList title="Insumos">
        <SideBarCard03
          title1="Baquelite Granulado e em Pó"
          imgPath1={Baquelite}
          route1=""
          title2="Luvas de borracha"
          imgPath2={Luvas}
          route2=""
        />
        <SideBarCard03
          title1="Discos de corte"
          imgPath1={Discos}
          route1=""
          title2="Lixas D'água"
          imgPath2={Lixa}
          route2=""
        />
        <SideBarCard03
          title1="Pano de Polimento"
          imgPath1={PanoPoli}
          route1=""
          title2="Pasta de Diamante"
          imgPath2={PastaDiam}
          route2=""
        />
        <SideBarCard03
          title1="Cilindro de nitrogênio"
          imgPath1={CilindroNitrogenio}
          route1=""
          title2="Filamento de tungstênio"
          imgPath2={FilamentoTungstenio}
          route2=""
        />
      </SideBarList>
    </Container>
  );
}
