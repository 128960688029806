import React from 'react';

import {
  Container,
  Image,
  InfoContainer,
  InfoText,
  Body,
  Margin,
  SubImages,
  SubImagesLeft,
  SubImagesRight,
  IconImage,
  LabImage,
  SubContainer,
  SubText,
} from './styles';

export default function CentralCard05(props) {
  return (
    <>
      <Container onClick={props.Click}>
        <InfoContainer>
          <Image src={props.imgSrc1} alt="" />
          <Image src={props.imgSrc2} alt="" />
        </InfoContainer>
        <Body>
          <InfoText>{props.text1}</InfoText>
        </Body>
        <Margin />
        <SubContainer>
          <SubImages>
            <SubImagesLeft>
              <IconImage src={props.imgSrc3} alt="" id={props.id1} />
              <IconImage src={props.imgSrc4} alt="" id={props.id2} />
              <IconImage src={props.imgSrc5} alt="" id={props.id} />
            </SubImagesLeft>
            <SubImagesRight>
              <LabImage src={props.imgSrc6} alt="" />
              <LabImage src={props.imgSrc7} alt="" />
              <LabImage src={props.imgSrc8} alt="" />
            </SubImagesRight>
          </SubImages>
          <SubText>{props.text2}</SubText>
        </SubContainer>
      </Container>
    </>
  );
}
