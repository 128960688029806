import React from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Typography from '@material-ui/core/Typography';

export default function CardModel1(props) {
  return (
    <Card id={props.id}>
      <CardActionArea id="card3">
        <CardMedia
          id="image"
          classcomponent="img"
          alt=""
          image={props.imgSrc}
          title=""
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
