import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled(Link)`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  word-break: normal;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  height: 100% !important;
  border-radius: 0.25rem;
  margin: 0.325rem;
  margin-right: 20px !important;
  text-decoration: none;
  width: 148px;
  text-align: center;
  margin-top: 20px !important;
  &:hover {
    text-decoration: none;
    transform: scaleX(1.025) scaleY(1.025);
  }
`;

export const Img = styled.div`
  display: flex;
  width: 148px;
  height: 112px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  border-image-width: 148px;
  justify-content: center;
  background: #fff;
  filter: none;

  img {
    align-self: center;
    border-radius: 8px;
    filter: grayscale(1);
  }
`;

export const Title = styled.p`
  font-size: 0.875rem;
  color: #888;
  line-height: 1.15;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Body = styled.div`
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  align-self: center;
  padding: 0.25rem;
`;
