import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  justify-content: space-between;
  border-radius: 0.25rem;
  margin: 0.5rem;
  text-decoration: none;
  width: 300px !important;
  text-align: center;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  margin-top: 18px !important;
  justify-content: flex-start;

  &:hover,
  &:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    transform: scaleX(1.0125) scaleY(1.0125);
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: line;
  margin-top: 12px;
  flex: 0 0 auto;
  height: 95px;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
`;

export const Margin = styled.hr`
  width: 276px;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Image = styled.img`
  margin-left: 18px;
  border-radius: 6px;
  flex: 0 0 auto;
`;

export const InfoText = styled.p`
  font-size: 12px;
  padding-top: 8px;
  padding-left: 18px;
  padding-right: 1rem;
  word-break: normal;
  text-align: initial;
  color: #777777;
  flex: 0 0 auto;
  margin-bottom: 0.25rem;
`;

export const SubImages = styled.div`
  display: flex;
  flex-direction: line;
  padding: 0 1rem;
  width: 100%;
  flex: 0 0 auto;
`;
export const SubImagesLeft = styled.div`
  #menininha {
    margin: 0;
  }
  display: flex;
  flex-direction: line;
  justify-content: flex-start;
  width: 100%;
`;
export const SubImagesRight = styled.div`
  display: flex;
  flex-direction: line;
  justify-content: flex-end;
  width: 100%;
`;

export const IconImage = styled.img`
  margin-right: 0.5rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: ${(props) => (props.src ? '30px' : '0px')};
  height: ${(props) => (props.src ? '30px' : '0px')};
`;

export const LabImage = styled.img`
  margin-left: 5px;
  border-radius: 4px;
  height: 30px;
  object-fit: cover;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0 0 auto;
  justify-content: space-between;
`;

export const SubText = styled.p`
  flex: 1 0 auto;
  font-size: 12px;
  line-height: 14px;
  padding-top: 8px;
  padding-left: 18px;
  word-break: normal;
  text-align: initial;
  color: rgba(119, 119, 119, 0.25);
  margin-bottom: 0;
  padding-bottom: 8px;
`;
