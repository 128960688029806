import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: line;
  background-color: #f8f9fa !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  width: 100%;
  color: #495057;
  text-align: inherit;
  text-decoration: none;
`;
export const InfoCard = styled.a`
  width: 100%;
  display: flex;
  word-wrap: break-word;

  &:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
    background-color: #dae0e5 !important;
  }

  &:active {
    color: #212529;
    background-color: #e9ecef;
    background-color: #dae0e5 !important;
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.875rem;

  img {
    align-self: center;
    margin-top: 1rem;
    width: 70px !important;
    height: 70px !important;
    border-radius: 6px;
    object-fit: cover;
    filter: grayscale(100%) opacity(70%);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
`;

export const InfoTitle = styled.div`
  width: 70px;
  padding-top: 0.875rem;
  font-weight: 600;
  font-size: 12px;
  height: 48;
  line-height: 16px;
  color: #333333;
  text-align: center;
  align-self: center;
  word-break: normal;
`;
