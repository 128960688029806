import React from 'react';
import { Container } from './styles';

import SideBarList from '../../../../components/SideBarList';
import SideBarCard02 from '../../../../components/SideBarCard02';

import LCME from '../../img/lcme.PNG';
import Cermat from '../../img/Cermat.png';
import LabMat from '../../img/labmat.png';
import Labsolda from '../../img/labsolda.png';

export default function ContentLeft() {
  return (
    <Container>
      <SideBarList title="Laboratórios relacionados">
        <SideBarCard02
          imgPath={Labsolda}
          title="LABSOLDA"
          subtitle="Instituto de Soldagem e Mecatrônica"
          route="/labsolda"
        />
        <SideBarCard02
          imgPath={LabMat}
          title="LabMat"
          subtitle="Laboratório de Materiais"
          route="/labmat"
        />
        <SideBarCard02
          imgPath={Cermat}
          title="CERMAT"
          subtitle="Núcleo de Pesquisas em Materiais Cerâmicos e Compósitos"
          route="/cermat"
        />
        <SideBarCard02
          imgPath={LCME}
          title="LCME"
          subtitle="Laboratório Central de Microscopia Eletrônica"
          route="/lcme"
        />
      </SideBarList>
    </Container>
  );
}
